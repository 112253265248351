import React from 'react';
import Helmet from 'react-helmet';
import getCurrentMenuId from '../common/providers/MetadataProvider/getCurrentMenuId';
import { Container, ListGroup } from 'react-bootstrap';
import { withMetadata } from '../common/providers';
import './RevCenterList.scss';

const RevCenterList = ({ revCenterGroup, onSelect }) => {
  const renderRevCenter = (rev) => {
    const curMenuId = getCurrentMenuId(rev.menuGroup.menus);

    return (
      <ListGroup.Item
        action
        key={rev.terminalId}
        className="revlist__item"
        disabled={!curMenuId}
        onClick={() => onSelect && onSelect(rev)}
      >
        <div>
          <p className="revlist__name">{rev.salesType}</p>
          {!curMenuId && <small className="text-muted text-uppercase">Not accepting orders</small>}
        </div>
        {rev.logoImageUrl && <img className="revlist__logo" src={rev.heroImageUrl} alt={rev.name} />}
      </ListGroup.Item>
    );
  };

  return (
    <Container className="my-3">
      <Helmet>
        <title>Select Store</title>
      </Helmet>

      <ListGroup>
        {revCenterGroup.revCenters.map(renderRevCenter)}
      </ListGroup>
    </Container>
  );
};

export default withMetadata(RevCenterList);