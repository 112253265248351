import React from 'react';
import PropTypes from 'prop-types';
import { PlayerRewardInquiryForm } from './rewards'
import { HaloInquiryForm } from './halo';
import { RoomChargeForm } from './pms';
import { Modal } from 'react-bootstrap';
import { getTerminalSetting } from '../common/TerminalHelpers';
import * as TerminalSettings from '../common/TerminalSettings';
import { isHalo } from '../common/TenderTypeHelpers';
import { compose } from "recompose";
import { withMetadata } from "../common/providers";

const PaymentTypeForms = {
  PMS: RoomChargeForm,
  PlayerRewards: PlayerRewardInquiryForm,
  Other: HaloInquiryForm,
  Membership: PlayerRewardInquiryForm,
};

const PaymentModal = ({
  show,
  check,
  tenderType,
  terminal,
  onConfirm,
  onCancel,
}) => {
  const Form = PaymentTypeForms[tenderType.paymentType];
  
  const getAccountIdRegex = () => {
    
    if (isHalo(tenderType._data.Tender)) {
      return getTerminalSetting(terminal, TerminalSettings.HOUSE_ADVANTAGE_TERMINAL_SETTING_NAME);
    }
    
    return null;
  };
  
  const accountIdRegex = getAccountIdRegex();

  return (
    <Modal show={show} backdrop="static">
      <Modal.Body className="p-4">
        <Form check={check} tenderType={tenderType} onConfirm={onConfirm} onCancel={onCancel} accountIdRegex={accountIdRegex} />
      </Modal.Body>
    </Modal>
  );
};

PaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  check: PropTypes.object.isRequired,
  tenderType: PropTypes.object.isRequired,
};

export default compose(
  withMetadata,
)(PaymentModal);
