import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { createCurrencyMask, getCurrency } from '../helpers';

const StyledMaskedInput = styled(MaskedInput)`
  text-align: right;
`;

export default function CurrencyInput({
  value,
  onBlur,
  onChange,
  ...props
}) {
  const mask = React.useMemo(() => createCurrencyMask(), []);

  const handleBlur = (e) => {
    const currencyValues = getCurrency(e.target.value);
    onBlur(e, currencyValues);
  };

  const handleChange = (e) => {
    const currencyValues = getCurrency(e.target.value);
    onChange(e, currencyValues);
  };

  return (
    <StyledMaskedInput
      mask={mask}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
}