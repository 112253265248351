import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Col, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { CurrencyInput } from '../common/components';
import { getCurrency } from '../common/helpers';
import { ThemedButton, ThemedFlatButton } from '../common/Themed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const InquirySchema = Yup.object().shape({
  accountId: Yup.string().required('Card number is required'),
  pin: Yup.string().required('Pin is required'),
});

const RoomInquirySchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  roomNumber: Yup.string().required('Room number is required'),
});

const Title = styled.h3`
  margin-bottom: 1rem;
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #f8f8f8;
  margin: .5rem 0;
  border-radius: .5rem;
  cursor: pointer;
  width: 100%;
`;

const SelectIcon = styled(FontAwesomeIcon)`
  margin-right: .5rem;
  color: ${props => props.selected ? props.theme.primaryColor : '#000' };
`;

const RadioButton = ({ selected, text }) => (
  <>
    <SelectIcon selected={selected} icon={selected ? faCheckCircle : faCircle} />
    {text}
  </>
);

/**
 * Determine if tender type supports partial payments.
 * @param {string} builtInTenderType Built in tender type name.
 */
const canSupportPartialPay = (builtInTenderType) => /roomcharge|point/i.test(builtInTenderType);

/**
 * Determine if tender type can allow tip during partial payment.
 * @param {string} builtInTenderType Built in tender type name.
 * @returns {boolean} Tender tyep allows tipping.
 */
const canTipOnPartialPay = (builtInTenderType) => /roomcharge/i.test(builtInTenderType);

function InquiryForm({
  balance,
  results,
  tenderType,
  promptRoomNumber = false,
  onInquiry,
  onPosting,
  onCancel,
}) {
  const [working, setWorking] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [formattedTip, setFormattedTip] = React.useState(0);
  const [formattedAuthAmount, setFormattedAuthAmount] = React.useState(getCurrency(balance).formattedValue);
  const accountIdRef = React.useRef();
  const nameRef = React.useRef();
  const { numericValue: authAmount } = getCurrency(formattedAuthAmount);
  const { numericValue: tip } = getCurrency(formattedTip);
  const initialValues = promptRoomNumber ? { name: '', roomNumber: '' } : { accountId: '', pin: '' };
  const validationSchema = promptRoomNumber ? RoomInquirySchema : InquirySchema;
  const formattedBalance = getCurrency(balance).formattedValue;
  const showPartialPay = canSupportPartialPay(tenderType.builtInTenderType);
  const showTip = canTipOnPartialPay(tenderType.builtInTenderType);

  // If tip could be applied, it's presumed to be a tender
  // that can also auth for the entire balance (ex: room charge).
  // Otherwise, it has a limit to the balance of the "account"
  // (ex: $5 off has a limit of $5 even if balance of order is $10)
  const showMaxAsBalance = showTip;

  const getMaxAuthValue = (maxAuthAmount) => {
    if (showMaxAsBalance) {
      return formattedBalance;
    }

    const value = maxAuthAmount > balance ? balance : maxAuthAmount;

    return getCurrency(value).formattedValue;
  };

  const handleResultSelect = (index, maxAuthAmount) => {

    // If max auth amount is more than balance, make sure
    // initial auth amount doesn't exceed the balance
    const curAuthAmount = maxAuthAmount > balance ? balance : maxAuthAmount;

    // Reset max amount err if exceeded previously
    const newErrors = {...errors};
    delete newErrors.authAmount;

    const { formattedValue } = getCurrency(curAuthAmount);

    setFormattedAuthAmount(formattedValue);
    setSelectedIndex(index);
    setErrors(newErrors);
  };

  const handleInquiry = async (values) => {
    await onInquiry(values);
  };

  const handlePosting = async () => {
    setWorking(true);
    await onPosting(selectedIndex, { amount: authAmount, tip });
    setWorking(false);
  };

  const handleAuthAmountBlur = (_, data, maxAuthAmount) => {
    const { numericValue: curAmount, formattedValue } = data;

    /**
     * For coupons, `maxAuthAmount` will be its value (ex: $10 off).
     * Make sure that this amount is less than the balance.  If not,
     * the balance should be the max amount it can auth for.
     * 
     * ex: 
     * 
     * Balance: $ 5.00
     * Coupon:  $10.00
     * 
     * Max Auth:$ 5.00
     */
    const maxAuth = balance < maxAuthAmount ? balance : maxAuthAmount;
    

    const newErrors = { ...errors };
    delete newErrors.authAmount;

    if (typeof(curAmount) !== 'number') {
      newErrors.authAmount = 'Must be a valid amount';
    } else if (curAmount > maxAuth) {
      newErrors.authAmount = "Amount exceeds max";
    }

    setFormattedAuthAmount(formattedValue);
    setErrors(newErrors);
  };

  React.useEffect(() => {
    if (accountIdRef.current) {
      accountIdRef.current.focus();
    }

    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  if (!results) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleInquiry}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) =>

          <FormikForm>

            <Title>{tenderType.name}</Title>

            {!promptRoomNumber &&
              <>
                <Form.Group>
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    name="accountId"
                    ref={accountIdRef}
                    isInvalid={!!errors.accountId && !!touched.accountId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.accountId}
                  />
                  <Form.Control.Feedback type="invalid">{errors.accountId}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Pin</Form.Label>
                  <Form.Control
                    name="pin"
                    type="password"
                    isInvalid={!!errors.pin && !!touched.pin}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.pin}
                  />
                  <Form.Control.Feedback type="invalid">{errors.pin}</Form.Control.Feedback>
                </Form.Group>
              </>
            }

            {promptRoomNumber &&
              <>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    ref={nameRef}
                    isInvalid={!!errors.firstName && !!touched.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    isInvalid={!!errors.lastName && !!touched.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Room Number</Form.Label>
                  <Form.Control
                    name="roomNumber"
                    isInvalid={!!errors.roomNumber && !!touched.roomNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.roomNumber}
                  />
                  <Form.Control.Feedback type="invalid">{errors.roomNumber}</Form.Control.Feedback>
                </Form.Group>
              </>
            }

            <ThemedButton
              block
              className="d-flex justify-content-center align-items-center"
              type="submit"
              disabled={isSubmitting}
            >
              Continue {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
            </ThemedButton>

            <ThemedFlatButton
              block
              variant="link"
              onClick={() => onCancel()}
            >
              Cancel
            </ThemedFlatButton>

          </FormikForm>

        }
      </Formik>
    );
  }

  return (
    <>
      <Title>{tenderType.name}</Title>

      {!results.length && <em className="d-block text-muted ml-2 mb-4">No results found</em>}

      {results &&
        <>
          {results.map((res, index) =>
            <Result key={index}>
              <div>
                <div onClick={() => handleResultSelect(index, showMaxAsBalance ? balance : res.amount)}>
                  <RadioButton text={res.name} selected={selectedIndex === index} />
                </div>

                {showPartialPay && selectedIndex === index &&
                  <div className="mt-3">
                    <Form.Group>
                      <Form.Row>
                      <Form.Label column="sm" xs={3}>Amount</Form.Label>
                      <Col xs={9}>
                        <CurrencyInput
                          className="form-control form-control-sm"
                          name="authAmount"
                          placeholder={showMaxAsBalance ? formattedBalance : getCurrency(res.amount).formattedValue}
                          value={formattedAuthAmount}
                          onBlur={(e, data) => handleAuthAmountBlur(e, data, showMaxAsBalance ? balance : res.amount)}
                          onChange={e => setFormattedAuthAmount(e.target.value)}
                        />
                        <small className="d-block text-muted text-right mt-1">
                          (MAX: {' '}
                          <strong>
                            {getMaxAuthValue(showMaxAsBalance ? balance : res.amount)}
                          </strong>
                          )
                        </small>
                        {errors.authAmount && <small className="d-block text-right text-danger">{errors.authAmount}</small>}
                      </Col>
                      </Form.Row>
                    </Form.Group>

                    {showTip &&
                      <Form.Group>
                        <Form.Row>
                        <Form.Label column="sm" xs={3}>Tip</Form.Label>

                        <Col xs={9}>
                          <CurrencyInput
                            className="form-control form-control-sm"
                            name="tip"
                            value={formattedTip}
                            onBlur={(_, { formattedValue }) => setFormattedTip(formattedValue)}
                            onChange={e => setFormattedTip(e.target.value)}
                          />
                        </Col>

                        </Form.Row>
                      </Form.Group>
                    }

                  </div>
                }

              </div>
            </Result>
          )}

          <ThemedButton
            block
            className="d-flex justify-content-center align-items-center"
            onClick={handlePosting}
            disabled={selectedIndex === null || !!Object.keys(errors).length || working}
          >
            Confirm{' '}
            {typeof(selectedIndex) === 'number' && getCurrency(authAmount + tip).formattedValue + ' '}
            {working && <Spinner animation="border" size="sm" className="ml-2" />}
          </ThemedButton>
        </>
      }

      <ThemedFlatButton
        block
        variant="link"
        onClick={() => onCancel()}
      >
        Cancel
      </ThemedFlatButton>
    </>
  );
}

InquiryForm.propTypes = {
  tenderType: PropTypes.object.isRequired,
  promptRoomNumber: PropTypes.bool,
  onInquiry: PropTypes.func.isRequired,
  onPosting: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InquiryForm;