
export const getReceipt = async (revenueCenterId, orderId, receiptId) => {
  const receiptsBaseUrl = process.env.REACT_APP_RECEIPTS_BASE_URL;

  const receiptEndpoint = `${receiptsBaseUrl}/${revenueCenterId}/${orderId}/${receiptId}`;

  const fetchRequest = {
    method: 'GET',
  };

  const response = await fetch(receiptEndpoint, fetchRequest);

  if (response.ok) {
    const receipt = response.json();

    return receipt;
  }

  throw Error(response);
};