import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import { withSettings } from '../providers';
import './MenuFilter.scss';

const Option = styled.div`
  color: ${props => props.selected ? props.theme.primaryColor : '#000'};
`;

const MenuFilter = ({
  history,
  options,
  selectedId,
}) => {
  const [show, setShow] = React.useState(false);
  const selected = options.find(opt => opt.id === selectedId);

  const handleClick = (option) => {
    setShow(false);
    history.push(option.link);
  };
  
  return (
    <div className="menufilter">
      <div className="menufilter__namecontainer" onClick={() => setShow(!show)}>
        <div className="menufilter__selectedname">{selected.name}</div>
        <Button
          variant="link"
          className="ml-3 mb-0 p-0 rounded-circle font-weight-bolder text-dark h3"
        >
          <i className={`fa 2x fa-chevron-${show ? 'up' : 'down'}`} />
        </Button>
      </div>

      <Collapse in={show}>
        <div>

          <ListGroup className="menufilter__list shadow-sm">
            {options.map(opt =>
              <ListGroup.Item
                action
                key={opt.id}
                className="menufilter__item text-dark text-decoration-none"
                onClick={() => handleClick(opt)}
              >
                <Option
                  className={`menufilter__option ${selectedId === opt.id ? 'menufilter__option--selected' : ''}`}
                  selected={selectedId === opt.id}
                >
                  {opt.name}
                </Option>
              </ListGroup.Item>
            )}
          </ListGroup>

        </div>
      </Collapse>
    </div>
  );
};

MenuFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  selectedId: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  withSettings,
)(MenuFilter);