import React from 'react';
import styled from 'styled-components';

const Promo = styled.img`
  width: 100%;
`;

const PromoList = ({ promos }) => (
  <div>
    {promos.map((promo, index) =>
      <div key={index} className="text-center py-4">
        <a href={promo.url} target="_blank" rel="noopener noreferrer">
          <Promo src={promo.image} alt={promo.image} />
        </a>
      </div>
    )}
  </div>
);

export default PromoList;