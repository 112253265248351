import Moment from 'moment';
import {
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_DISCOUNT,
  PAYMENT_TYPE_PMS,
  PAYMENT_TYPE_PLAYER_REWARD,
  SUPPORTED_ONLINE_PAYMENT,
  PAYMENT_TYPE_MEMBERSHIP,
} from '../../Constants';
import * as Cache from '../../LocalCache';
import getCurrentMenuId from './getCurrentMenuId';
import db from './metadataDb';

export async function verifyKey(key) {
  const resourceUrl = process.env.REACT_APP_VALIDATE_API;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ key }),
  };

  const result = await fetch(resourceUrl, options);
  if (!result.ok) {
    throw new Error(result.statusText);
  }

  return result.json();
}

export async function callDecodeLambda(action, order = '', payload) {
  const { token } = Cache.getSettings();
  const resourceUrl = process.env.REACT_APP_API_BASE_URL;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      token,
      action,
      payload,
      orderdata: order,
    }),
  };

  const result = await fetch(resourceUrl, options);
  if (!result.ok) {
    throw new Error(result);
  }

  const response = await result.json();
  return response;
}

export async function getTerminalLambda(terminal) {
  const tenderTypes = terminal.TenderTypes.reduce(
    (acc, tt) =>
      Object.assign(acc, {
        [tt.Id]: {
          id: tt.Id,
          name: tt.Name,
        },
      }),
    {}
  );

  return {
    tenderTypes,
    _data: terminal,
    id: terminal.Id,
    name: terminal.Name,
    menuGroupId: terminal.MenuGroupId,
    revCenterId: terminal.ProfitCenterId,
    defaultUserId: terminal.DefaultUserId,
  };
}

export async function getRevenueCenter(revCenter) {
  const promos = revCenter.Promos.map((promo) => ({
    image: promo.ImageUrl,
    url: promo.Url,
  }));

  const homePromos = revCenter.HomePromos.map((promo) => ({
    image: promo.ImageUrl,
    url: promo.Url,
  }));

  return {
    _data: revCenter,
    promos,
    homePromos,
    id: revCenter.Id,
    name: revCenter.Name,
    logoImageUrl: revCenter.LogoImageUrl,
    heroImageUrl: revCenter.HeroImageUrl,
    color: revCenter.Color,
    address: revCenter.ReceiptLine2,
    city: revCenter.City,
    state: revCenter.State,
    zip: revCenter.StoreZip,
    phone: revCenter.ReceiptLine4,
    notesDisabled: revCenter.NotesDisabled,
    propertyCode: revCenter.PropertyCode,
    brandLabel: revCenter.BrandLabel,
    landingPageTitle: revCenter.LandingPageTitle,
    closedMessage: revCenter.ClosedMessage,
    autoClose: revCenter.AutoClose,
    salesType: revCenter.SalesType,
  };
}

export async function getRevenueCenterGroup(profitCenterGroup) {
  const profitCenters = profitCenterGroup?.ProfitCenters || [];

  return {
    revCenters: profitCenters.map((pc) => ({
      terminalId: pc.TerminalId,
      revCenterId: pc.ProfitCenterId,
      name: pc.Name,
      salesType: pc.SalesType,
      logoImageUrl: pc.LogoImageUrl,
      heroImageUrl: pc.HeroImageUrl,
      orderUrl: pc.OrderUrl,
      menuGroup: {
        id: pc.MenuGroup.Id,
        name: pc.MenuGroup.Name,
        menus: pc.MenuGroup.Menus.reduce(
          (acc, menu) => ({
            ...acc,
            [menu.Id]: {
              daysToRunFlags: menu.DaysToRunFlags,
              start: Moment(menu.StartTime).format('HH:mm:ss'),
              end: Moment(menu.EndTime).format('HH:mm:ss'),
            },
          }),
          {}
        ),
      },
    })),
  };
}

export async function getTenderTypes(terminal, tenderType) {
  const tenderTypes = {
    supported: {
      [PAYMENT_TYPE_CC]: null, // Only allow one
      [PAYMENT_TYPE_DISCOUNT]: null, // Only allow one
      [PAYMENT_TYPE_PLAYER_REWARD]: [], // Allow multiple (ex: points, comps, etc.)
      [PAYMENT_TYPE_PMS]: null,
      [PAYMENT_TYPE_MEMBERSHIP]: null, // Only allow one
    },
  };

  for (let tType of tenderType) {
    const curPaymentType = tType.Tender.PaymentType;
    const curBuiltInTenderType = tType.Tender.BuiltInTenderType;

    Object.assign(tenderTypes, {
      [tType.Id]: {
        _data: tType,
        id: tType.Id,
        name: tType.Name,
        paymentType: curPaymentType,
        builtInTenderType: tType.Tender.BuiltInTenderType,
        isTaxExempt: tType.IsExemptTax,
      },
    });
    switch (curPaymentType) {
      case PAYMENT_TYPE_PLAYER_REWARD:
        tenderTypes.supported = {
          ...tenderTypes.supported,
          [PAYMENT_TYPE_PLAYER_REWARD]: tenderTypes.supported[PAYMENT_TYPE_PLAYER_REWARD].concat([tType.Id]),
        };
        break;
      case PAYMENT_TYPE_CC:
        if (curBuiltInTenderType === SUPPORTED_ONLINE_PAYMENT) {
          tenderTypes.supported = {
            ...tenderTypes.supported,
            [curPaymentType]: tType.Id,
          };
        }
        break;
      case PAYMENT_TYPE_PMS:
      case PAYMENT_TYPE_DISCOUNT:
      case PAYMENT_TYPE_MEMBERSHIP:
        tenderTypes.supported = {
          ...tenderTypes.supported,
          [curPaymentType]: tType.Id,
        };
        break;
      default:
        console.warn(`Unsupported tender type: ${curPaymentType} ${tType.Id}`);
    }
  }

  return tenderTypes;
}

export async function getMenusLambda(menuObj) {
  const menus = {};
  for (let menu of menuObj) {
    const productGroups = {};
    for (let pg of menu.ProductGroups) {
      Object.assign(productGroups, {
        [pg.Id]: {
          id: pg.Id,
          name: pg.Name,
          description: pg.Description,
          position: pg.MenuPosition,
        },
      });
    }

    Object.assign(menus, {
      [menu.Id]: {
        productGroups,
        _data: menu,
        id: menu.Id,
        name: menu.Name,
        defaultProductGroupId: menu.DefaultSelectedProductGroupId,
        start: Moment(menu.StartTime).format('HH:mm:ss'),
        end: Moment(menu.EndTime).format('HH:mm:ss'),
        orderByWeight: menu.OrderMenuItemsByWeight,
        daysToRunFlags: menu.DaysToRunFlags,
      },
    });
  }
  return menus;
}

export async function getProductGroupsLambda(productGroupsObj) {
  const productGroups = {};
  const productCatalog = {};

  for (let pGroup of productGroupsObj) {
    const products = {};
    const revenueTypes = {};

    for (let prod of pGroup.Products) {
      Object.assign(products, {
        [prod.Id]: {
          id: prod.Id,
          name: prod.LongName,
          description: prod.Description,
          price: +prod.Price,
          position: prod.MenuPosition,
          externalId: prod.ExternalId,
        },
      });

      if (!productCatalog[prod.Id]) {
        Object.assign(productCatalog, {
          [prod.Id]: {
            id: prod.Id,
            name: prod.LongName,
            description: prod.Description,
            price: +prod.Price,
            image: prod.ImageBase64,
            position: prod.MenuPosition,
            externalId: prod.ExternalId,
            productGroupIds: [],
          },
        });
      }

      // Set temp value. This makes it easier not to add duplicates.
      // This will be turned into array on the prod group.
      revenueTypes[prod.RevenueTypeId] = true;

      productCatalog[prod.Id].productGroupIds.push(pGroup.Id);
    }

    const exceptionModifierGroups = {};
    for (let prodExModGrp of pGroup.ExceptionModifierGroups) {
      Object.assign(exceptionModifierGroups, {
        [prodExModGrp.Id]: {
          _data: prodExModGrp,
          id: prodExModGrp.Id,
          name: prodExModGrp.Name,
          position: prodExModGrp.MenuPosition,
        },
      });
    }

    Object.assign(productGroups, {
      [pGroup.Id]: {
        products,
        id: pGroup.Id,
        name: pGroup.Name,
        description: pGroup.Description,
        orderByWeight: pGroup.OrderMenuItemsByWeight,
        revenueTypes: Object.keys(revenueTypes),
        exceptionModifierGroups,
      },
    });
  }

  await db
    .transaction('rw', db.products, async () => {
      const keys = Object.keys(productCatalog);

      for (const id of keys) {
        const prod = productCatalog[id];
        await db.products.put(prod);
      }
    })
    .catch((err) => console.error(err));

  return productGroups;
}

export function getProductsAndRevenueTypes(productsObj) {
  const products = {};
  const revenueTypes = {};

  for (let prod of productsObj) {
    const forcedModifiersGroups = {};

    for (let forcedmodgroups of prod.ForcedModifierGroups) {
      Object.assign(forcedModifiersGroups, {
        [forcedmodgroups.Id]: {
          _data: forcedmodgroups,
          id: forcedmodgroups.Id,
          name: forcedmodgroups.Name,
          position: forcedmodgroups.MenuPosition,
        },
      });
    }

    Object.assign(products, {
      [prod.Id]: {
        id: prod.Id,
        name: prod.LongName,
        description: prod.Description,
        price: +prod.Price,
        revenueTypeId: prod.RevenueTypeId,
        externalId: prod.ExternalId,
        forcedModifiersGroups: forcedModifiersGroups,
      },
    });

    revenueTypes[prod.RevenueTypeId] = { name: prod.RevenueType.Name };
  }

  return { products, revenueTypes };
}

/**
 * Update product images. Products should already be saved in indexedDB in a
 * table called **products**. Since image field might contain huge base64 images,
 * the images are optionally pulled *only* from Products OData endpoint.
 * @param {Array<object>} products Products fetched from OData Products endpoint.
 */
export async function updateProductImages(products) {
  await db.transaction('rw', db.products, async () => {
    for (const prod of products) {
      try {
        // Ignore result. This will return 0 on no update, or 1 if updated.
        await db.products.update(prod.Id, { image: prod.Image?.ImageBase64 });
      } catch (err) {
        console.error(err);
      }
    }
  });
}

export async function getExceptionModifierGroups(exceptionModifierGroups) {
  const exceptionModifierGroup = {};

  for (let exModGrp of exceptionModifierGroups) {
    const exceptionModifier = {};

    for (let exModifier of exModGrp.ExceptionModifiers) {
      Object.assign(exceptionModifier, {
        [exModifier.Id]: {
          id: exModifier.Id,
          name: exModifier.Name,
          price: +exModifier.Price,
          position: exModifier.MenuPosition,
          _data: exModifier,
        },
      });
    }

    Object.assign(exceptionModifierGroup, {
      [exModGrp.Id]: {
        id: exModGrp.Id,
        name: exModGrp.Name,
        exceptionModifier,
        orderByWeight: exModGrp.OrderMenuItemsByWeight,
      },
    });
  }
  return exceptionModifierGroup;
}

export function getExceptionModifiersAll(exceptionModifierGroups) {
  const exceptionModifiers = {};

  Object.keys(exceptionModifierGroups).forEach((exModGrpId) => {
    const exModGrp = exceptionModifierGroups[exModGrpId];

    Object.keys(exModGrp.exceptionModifier).forEach((exModId) => {
      const exMod = exModGrp.exceptionModifier[exModId]._data;

      Object.assign(exceptionModifiers, {
        [exModId]: {
          _data: exMod,
          id: exModId,
          name: exMod.Name,
          price: exMod.Price,
        },
      });
    });
  });

  return exceptionModifiers;
}

export async function getForcedModifierGroupsAll(forcedModiferGroups) {
  const forcedModifierGroups = {};

  for (let fmodgroup of forcedModiferGroups) {
    const forcedModifiers = {};

    for (let fm of fmodgroup.ForcedModifiers) {
      Object.assign(forcedModifiers, {
        [fm.Id]: {
          id: fm.Id,
          name: fm.Name,
          price: +fm.Price,
          position: fm.MenuPosition,
          _data: fm,
        },
      });
    }

    Object.assign(forcedModifierGroups, {
      [fmodgroup.Id]: {
        _data: fmodgroup,
        id: fmodgroup.Id,
        name: fmodgroup.Name,
        min: fmodgroup.MinimumChoice,
        max: fmodgroup.MaximumChoice,
        orderByWeight: fmodgroup.OrderMenuItemsByWeight,
        forcedModifiers,
      },
    });
  }

  return forcedModifierGroups;
}

export function getForcedModifiersAll(forcedModifierGroups) {
  const forcedModifiersObject = {};
  Object.keys(forcedModifierGroups).forEach((forcedModGrpId) => {
    const forcedMod = forcedModifierGroups[forcedModGrpId].forcedModifiers;
    Object.keys(forcedMod).forEach((fmId) => {
      const forcedModifier = forcedMod[fmId];

      Object.assign(forcedModifiersObject, {
        [forcedModifier.id]: forcedModifier,
      });
    });
  });

  return forcedModifiersObject;
}

/**
 * Set temp metadata. Although this is created like an async function,
 * everything in here is synchronous.
 * TODO: refactor to fetching real data instead of pass thru.
 * @param {*} terminalauth Metadata object received from validate lambda.
 */
export async function setStagingCacheData(terminalauth) {
  try {
    const terminal = await getTerminalLambda(terminalauth.terminal);
    Cache.setTempTerminal(terminal);

    if (terminal && !terminal._data.DefaultUserId) {
      console.warn('DefaultUserId is not set on the terminal. This may affect order related transactions.');
    }

    const revCenter = await getRevenueCenter(terminalauth.profitCenters, terminalauth.terminal.TerminalSettings || []);
    Cache.setTempRevCenter(revCenter);

    const revCenterGroup = await getRevenueCenterGroup(terminalauth.profitCenterGroup);
    Cache.setTempRevCenterGroup(revCenterGroup);

    const tenderTypes = await getTenderTypes(terminal, terminalauth.tenderTypes);
    Cache.setTempTenderTypes(tenderTypes);

    const menus = await getMenusLambda(terminalauth.menu);
    Cache.setTempMenus(menus);

    const productGroups = await getProductGroupsLambda(terminalauth.productGroups);
    Cache.setTempProductGroups(productGroups);

    const { products, revenueTypes } = await getProductsAndRevenueTypes(terminalauth.products);
    Cache.setTempProducts(products);
    Cache.setTempRevenueTypes(revenueTypes);

    // 1. Products get saved in indexedDB when fetching product groups
    // 2. Products get fetched with optional ImageBase64 field
    // 3. This method updates product images in indexedDB
    await updateProductImages(terminalauth.products);

    const exceptionModifierGroups = await getExceptionModifierGroups(terminalauth.exceptionModifierGroups);
    Cache.setTempExceptionModifierGroups(exceptionModifierGroups);

    const exceptionModifiers = await getExceptionModifiersAll(exceptionModifierGroups);
    Cache.setTempExceptionModifiers(exceptionModifiers);

    const forcedModifierGroups = await getForcedModifierGroupsAll(terminalauth.forcedModiferGroups);
    Cache.setTempForcedModifierGroups(forcedModifierGroups);

    const forcedModifiersNew = await getForcedModifiersAll(forcedModifierGroups);
    Cache.setTempForcedModifiers(forcedModifiersNew);
  } catch (err) {
    console.error(err);
  }
}

export function refreshMetadata() {
  const menus = Cache.getTempMenus() || null;
  const productGroups = Cache.getTempProductGroups() || null;
  const products = Cache.getTempProducts() || null;
  const exceptionModifierGroups = Cache.getTempExceptionModifierGroups() || null;
  const exceptionModifiers = Cache.getTempExceptionModifiers() || null;
  const forcedModifierGroups = Cache.getTempForcedModifierGroups() || null;
  const forcedModifiers = Cache.getTempForcedModifiers() || null;
  const revenueTypes = Cache.getTempRevenueTypes() || null;

  const terminal = Cache.getTempTerminal() || null;
  const revCenter = Cache.getTempRevCenter() || null;
  const revCenterGroup = Cache.getTempRevCenterGroup() || null;
  const tenderTypes = Cache.getTempTenderTypes() || null;
  const curMenuId = getCurrentMenuId(menus) || null;

  // Set latest cache
  Cache.setCurMenuId(curMenuId);
  Cache.setMenus(menus);
  Cache.setProductGroups(productGroups);
  Cache.setProducts(products);
  Cache.setExceptionModifierGroups(exceptionModifierGroups);
  Cache.setExceptionModifiers(exceptionModifiers);
  Cache.setForcedModifierGroups(forcedModifierGroups);
  Cache.setForcedModifiers(forcedModifiers);
  Cache.setRevenueTypes(revenueTypes);

  Cache.setTerminal(terminal);
  Cache.setTenderTypes(tenderTypes);
  Cache.setRevCenter(revCenter);
  Cache.setRevCenterGroup(revCenterGroup);

  const metadata = {
    curMenuId,
    menus,
    productGroups,
    products,
    exceptionModifierGroups,
    exceptionModifiers,
    forcedModifierGroups,
    forcedModifiers,
    tenderTypes,
    terminal,
    revCenter,
    revCenterGroup,
    revenueTypes,
  };

  return metadata;
}

export async function resetMetadata() {
  Cache.clearExceptRevCenter();

  // Clear indexedDB
  await db.products.clear();

  return {
    curMenuId: null,
    menus: null,
    productGroups: null,
    products: null,
    exceptionModifierGroups: null,
    exceptionModifiers: null,
    forcedModifierGroups: null,
    forcedModifiers: null,
    tenderTypes: null,
    terminal: null,
  };
}
