import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal
} from 'react-bootstrap';

import { withSettings } from '../../common/providers';
import { SwipeGif } from '../../common/images';

const RewardPrompt = ({
  settings,
  show,
  onCancel,
  onSwipe,
}) => {
  const [cardSwipe, setCardSwipe] = React.useState('');
  const { cardSwipeRegex, primaryColor } = settings;

  const handleCardCaptured = () => {
    let id;
    const result = cardSwipe.match(new RegExp(cardSwipeRegex));

    if (result) {
      id = result[0];
    }

    onSwipe({
      id,
      swipe: cardSwipe,
    });
  }

  React.useEffect(() => {
    const onKeyDown = (event) => {
      setCardSwipe(cardSwipe + event.key);

      if (event.code === 'Enter') {
        handleCardCaptured();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Modal
      show={show}
      centered={true}
      onHide={() => onCancel && onCancel()}
    >
      <Modal.Body className="my-3 p-4 text-center">
        <h3 className="font-weight-bolder">Swipe Your Player Card</h3>

        <img 
          className="my-4 rounded-circle"
          src={SwipeGif}
          alt="Swipe" 
          style={{
            backgroundColor: primaryColor,
            width: '40%',
          }}
        />

        <p className="mb-0 text-uppercase">Waiting...</p>
      </Modal.Body>
    </Modal>
  );
};

RewardPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  onSwipe: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

export default withSettings(RewardPrompt);