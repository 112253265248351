import React, {useEffect} from 'react';
import {useTheme} from 'styled-components';
import {replaceColor} from 'lottie-colorify';
import {PlayerCardSwipeLottieData} from '../images';
import Lottie from '../components/Lottie';

// See lottie animation at: https://iconscout.com/lottie-animation/swipe-card-3428884
// Note: This animation has been slightly altered from original
const CardSwipeLottie = () => {
  const [colorModifiedLottie, setColorModifiedLottie] = React.useState(null);
  const { primaryColor } = useTheme();

  useEffect(() => {
    const modifiedLottie = primaryColor
      ? replaceColor('#FDCA00', '#C0412D', PlayerCardSwipeLottieData)
      : PlayerCardSwipeLottieData;
    
    setColorModifiedLottie(modifiedLottie);
  }, [primaryColor, replaceColor]);
  
  return (
    <>
      {colorModifiedLottie &&
        <Lottie animationData={colorModifiedLottie} loop />
      }
    </>
  );
};

export default CardSwipeLottie;