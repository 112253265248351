import React from 'react';
import PropTypes from 'prop-types';
import * as PosApi from '../../common/PosApi';
import InquiryForm from '../InquiryForm';
import { compose } from 'recompose';
import { withMetadata, withToast } from '../../common/providers';

function PlayerRewardInquiryForm({
  check,
  tenderType,
  onConfirm,
  onCancel,
  toast,
  terminal,
  accountIdRegex,
}) {
  const [results, setResults] = React.useState();

  const handleInquiry = async ({ accountId, pin }) => {
    try {
      let formattedAccountId = accountId;
      
      // Format the user's input according to the provided regex if provided
      if (accountIdRegex)
        formattedAccountId = formatAccountId(accountId, accountIdRegex) || accountId;
      
      console.log('Inquiring using account id: ', formattedAccountId);
      
      const inquiryRequest = {
        AccountId: formattedAccountId,
        Pin: pin,
        OrderId: check.id,
        TenderTypeId: tenderType.id,
        TerminalId: terminal.id,
      };

      const accounts = await PosApi.postInquiry(inquiryRequest);

      // PosApi requires sending back the exact account obj
      // when calling subsequent POSTING in ExtendedData.
      // Save off the account in _data for each result.
      const accountResults = accounts.map(acct => ({
        name: acct.displayName,
        amount: acct.balance,
        _data: acct,
      }));

      setResults(accountResults);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  const handlePosting = async (selectedIndex, data) => {
    try {
      const account = results[selectedIndex];
      const { amount, tip } = data;

      const payment = {
        check,
        amount,
        tip,
        name: account.name,
        tenderTypeId: tenderType.id,
        extendedData: account._data,
      };

      const transaction = await PosApi.postTransaction(payment);
      onConfirm(transaction);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };
  
  const formatAccountId = (accountNumber, accountIdRegexValue) => {
    const accountIdRegex = new RegExp(accountIdRegexValue);
    
    const matches = accountNumber.match(accountIdRegex);
    
    let formattedAccountId = accountNumber;
    
    if (!matches) {
      return null;
    } else {
      formattedAccountId = matches[0];
    }
    
    return formattedAccountId;
  };

  return <InquiryForm
    results={results}
    balance={tenderType.isTaxExempt ? check.taxExemptBalance : check.balance}
    tenderType={tenderType}
    onInquiry={handleInquiry}
    onPosting={handlePosting}
    onCancel={onCancel}
  />;
}

PlayerRewardInquiryForm.propTypes = {
  check: PropTypes.object.isRequired,
  tenderType: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default compose(
  withMetadata,
  withToast,
)(PlayerRewardInquiryForm);