import React, {createContext, useContext, useEffect, useState} from 'react';
import { Hook, Unhook } from 'console-feed';
import {getTerminalSettingAsBool} from '../TerminalHelpers';
import * as TerminalSettings from '../TerminalSettings';
import {MetadataContext} from './MetadataProvider';

const ConsoleLogContext = createContext({});

const maxLogs = 1000;

const ConsoleLogProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);

  const { terminal } = useContext(MetadataContext);
  
  const trackLogs = terminal
    ? getTerminalSettingAsBool(terminal, TerminalSettings.IS_DEVELOPER_MODE)
    : true;
  
  useEffect(() => {
    
    if (!trackLogs) {
      return;
    }
    
    const hookedConsole = Hook(
      window.console,
      (log) => setLogs((currLogs) => {
        const newLogs = [...currLogs, log];
      
        if (newLogs.length > maxLogs) {
          // Remove the oldest log
          newLogs.shift();
        }
      
        return newLogs;
      }),
      false
    );
    
    return () => Unhook(hookedConsole);
  }, []);
  
  const value = {
    logs,
  };
  
  return (
    <ConsoleLogContext.Provider value={value}>
      {children}
    </ConsoleLogContext.Provider>
  );
};

export {
  ConsoleLogProvider,
  ConsoleLogContext,
}