import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import  InfoBubble from './InfoBubble';
import './ItemizedCard.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { ModifierLineItem } from './ModifierLineItem';

const ItemizedCard = ({ items }) => {
  const itemsArray = Object
    .keys(items)
    .map(id => items[id]);

  const parentItems = itemsArray.filter(s => !s.parentSaleWhenModSaleId);

  return (
    <Card className="itemized">
      <Card.Body>
        <Row className="itemized__header">
          <Col xs={9}>
            <strong>Item</strong>
          </Col>
          <Col xs={3} className="itemized__price">
            <strong>Price</strong>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        {parentItems.map(item => {
          const modifierItems = itemsArray.filter(s => s.parentSaleWhenModSaleId === item.id);
          const modifierTotal = modifierItems.reduce((previousTotal, mod) => previousTotal + (mod.price * mod.quantity), 0);

          return (
            <Row key={item.id} className="mb-2">
              <Col xs={9}>
                <div className="d-flex justify-content-left">
                  <button className="itemized__quantity">
                    
                    {item.quantity > 99 ? '99+' : item.quantity.toString()}
                  </button>
                  <span className="itemized__name">{item.name}</span>
                </div>
                {modifierItems.map(mod =>
                  <div key={mod.id}>
                    {mod.isSeeServer === false &&
                      <ModifierLineItem styleName={"text-muted ml-5 mb-1 pl-5"} name={mod.name} price={mod.price}/>
                    }
                    {mod.notes != null &&
                      <p className = "text-muted ml-5 mb-1 pl-5">
                        Notes
                        <InfoBubble infoText={mod.notes}/> 
                      </p>
                    }               
                  </div>
                )} 
              </Col>
              <Col
                className="itemized__price itemized__price--offset"
                xs={3}
              >
                {Numeral((item.price * item.quantity) + modifierTotal).format('$0,0.00')}
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

ItemizedCard.propTypes = {
  items: PropTypes.object.isRequired,
};

export default ItemizedCard;