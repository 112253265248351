import React from 'react';
import './NumberPadButton.scss';
import classNames from 'classnames';

const NumberPadButton = ({ children, onClick, variant }) => {
  const buttonClass = classNames({
    "btn-num-pad": true,
    "btn-num-pad--danger": variant === "danger",
    "btn-num-pad--success": variant === "success",
    "btn-num-pad--gray": variant === "gray",
  });
  
  return (
    <button className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default NumberPadButton;