import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { withCheck } from '../providers';

const AuthRoute = ({
  location,
  valid,
  validations = [],
  redirectUrl,
  component: Component,
  ...rest
}) => {
  if (validations.length) {
    for (const val of validations) {
      if (!val.valid) {
        return <Redirect to={{ ...location, pathname: val.redirectUrl }} />;
      }
    }
  }

  if (typeof(valid) === 'boolean' && !valid) {

    // Redirect to provided path
    if (redirectUrl) {
      return <Redirect to={{ ...location, pathname: redirectUrl }} />;
    }

    // If not specified, redirect to connect auth path
    return <Redirect to={{ ...location, pathname: '/connect' }} />;
  }

  // Authorized!
  return <Route {...rest} render={props => <Component {...props} />} />;
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default compose(
  withCheck,
  withRouter,
)(AuthRoute);