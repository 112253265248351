import * as _ from 'lodash';
import Numeral from 'numeral';
import { createNumberMask } from 'text-mask-addons';

const CURRENCY_FORMAT = '$0,0.00';

/**
 * Sort items by name in ascending order. If `useWeight` is true,
 * sort items by `position` in descending order then sort by `name`
 * in ascending order.
 * @param {Array<object>} items Items to be sorted which **must** include `name` and `position` fields.
 * @param {boolean} useWeight Sort items by weight.
 */
export function sortItems(items, useWeight = false) {
  let sortFields = ['name'];
  let sortMethods = ['asc'];

  if (useWeight) {
    sortFields = ['position'].concat(sortFields);
    sortMethods = ['desc'].concat(sortMethods);
  }

  return _.orderBy(items, sortFields, sortMethods);
}

export function createCurrencyMask() {
  return createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalsSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false,
  });
}

/**
 * Gets the currency representation in different formats.
 * @param {string | number} value Any representation of currency.
 * @param {Object} options Options to pass in (ex: format).
 * @returns Numeric and formatted values for currency amount.
 */
export function getCurrency(value, options = {}) {
  const currencyFormat = options.format || CURRENCY_FORMAT;
  const numericValue = Numeral(value).value();
  const formattedValue = Numeral(numericValue).format(currencyFormat);

  return {
    numericValue,
    formattedValue,
  };
}