import { Formik, Form as FormikForm } from 'formik';
import React, { useEffect } from 'react';
import { Modal, Form } from "react-bootstrap";
import * as Yup from 'yup';
import { ThemedButton, ThemedFormControl, ThemedLinkButton } from '../common/Themed';

const EmailModal = ({ show, onCancel, onSubmit }) => {

  const emailSchema = Yup.object().shape({
    email: Yup
      .string()
      .required('Email is required')
      .email('Invalid email'),
  });

  const emailInputRef = React.useRef();

  useEffect(() => {
    if (show) {
      emailInputRef.current.focus();
    }
  }, [show]);

  const handleSubmit = async (values) => {
    const email = values.email;

    onSubmit(email);
  };

  return (
    <Modal
      centered={true}
      show={show}
      className="border-primary"
    >
      <Modal.Body>
        <Formik 
          initialValues={{ email: '' }}
          validationSchema={emailSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty, errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>
            <FormikForm>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <ThemedFormControl
                  name="email"
                  ref={emailInputRef}
                  isInvalid={!!errors.email && !!touched.email}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <div>
                <ThemedButton 
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Submit
                </ThemedButton>
                <ThemedLinkButton
                  variant="link"
                  onClick={() => onCancel()}
                >
                  Cancel
                </ThemedLinkButton>
              </div>
            </FormikForm>
          }
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EmailModal;