import React from 'react';
import styled from 'styled-components';

const Padded = styled.div`
  padding: 1rem 1rem 5rem;
`;

const Container = ({
  children,
  style,
  ...rest
}) => (
  
  <Padded style={{ paddingBottom: 100, ...style }} {...rest}>
    {children}
  </Padded>
);

export default Container;