import React from 'react';
import PropTypes from 'prop-types';
import NavButtonGroup from './NavButtonGroup';
import './TopNavBar.scss';

const TopNavBar = ({
  title,
  buttons = [],
  text,
}) => (
  <div className="topnav">
    <div className="topnav__title">{title}</div>
    <div>
      <NavButtonGroup buttons={buttons} />
    </div>
    {text &&
      <div>
        {text}
      </div>
    }
  </div>
);

TopNavBar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
}

export default TopNavBar;