import * as LocalCache from './LocalCache';
const VERSION = 'v1';

const handleError = (err) => {
  console.error(err);

  let message = err.message;

  if (!message || message.match(/failed to fetch.*/i)) {
    message = 'Server is offline.';
  }

  throw new Error(message);
};

/**
 * Helper method to handle Fetch API and House Advantage response.
 * @param {Object} httpResponse Response from Fetch API.
 */
const handleResponse = async (httpResponse) => {
  const response = await httpResponse.json();

  if (!response.result) {
    throw new Error(response.message);
  }

  return response;
};

const handleLambdaResponse = async (httpResponse) => {
  const result = await httpResponse.json();

  if (!result) {
    throw new Error(result.message);
  }

  return result;
};

/**
 * Execute a GET request using Fetch API.
 * @param {string} resourcePath Path to resource, typically just the name. Version path is automicatlly filled in.
 * @param {Object} settings Extended settings object with sessionId.
 */
const get = (resourcePath, settings) => fetch(`${settings.playerRewardUrl}/${VERSION}/${resourcePath}`, {
    headers: {
      HALicenseKey: settings.haLicenseKey,
      SessionID: settings.sessionId,
      'Content-Type': 'application/json',
    },
  })
  .then(handleResponse)
  .catch(handleError);

/**
 * Execute a POST request using Fetch API.
 * @param {string} resourcePath Path to resource, typically just the name. Version path is automicatlly filled in.
 * @param {Object} body The body of the request.
 * @param {Object} settings Extended settings object with sessionId.
 */
const post = (resourcePath, body, settings) => fetch(`${settings.playerRewardUrl}/${VERSION}/${resourcePath}`, {
    headers: {
      HALicenseKey: settings.haLicenseKey,
      SessionID: settings.sessionId,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body)
  })
  .then(handleResponse)
  .catch(handleError);

const postLambda = (url, request) => {
  const { token } = LocalCache.getSettings();

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(request),
  })
  .then(handleLambdaResponse)
  .catch(handleError)
};

export const inquiry = (request) => postLambda(process.env.REACT_APP_HALO_INQUIRY, request);

export const redeem = (request) => postLambda(process.env.REACT_APP_HALO_REDEEM, request);

/**
 * Start a session for discount requests. Must call endSession after discount transactions.
 * @param {Object} settings Settings object.
 * @returns {Promise<Object>} Session object with sessionId.
 */
export const startSession = (settings) => {
  const req = {
    clientID: settings.playerRewardClientId,
    authorizationKey: settings.playerRewardAuthorizationKey,
  };

  return post('startsession', req, settings)
    .then(res => res.data)
    .then(data => ({ sessionId: data.sessionID }));
};

/**
 * End current session to release discounts.
 * @param {Object} settings Extended settings object with sessionId.
 * @returns {Promise<void>}
 */
export const endSession = (settings) => get('endsession', settings);

/**
 * Get player information.
 * @param {string} accountId Account identifier from swipe data.
 * @param {string} pinNumber Specify pin if prompted. Not required.
 * @param {Object} settings Extended settings object with sessionId.
 */
export const getPlayerInfo = (accountId, pinNumber, settings) => {
  const terminal = LocalCache.getTerminal()._data;
  const revCenter = LocalCache.getRevCenter()._data;

  const req = {
    pinNumber,
    accountNumber: accountId,
    revenueCenterID: revCenter.ExternalId,
    terminalId: terminal.ExternalId,
  };
  
  return post('validateplayer', req, settings)
    .then(res => res.data)
    .then(data => ({
      id: data.accountNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      tierId: data.tierID,
      tierName: data.tierName,
      name: `${data.firstName} ${data.lastName}`,
    }));
};

/**
 * Get available discounts for player.
 * @param {string} accountId Player account identifier.
 * @param {string} tenderTypeId Tender type identifier.
 * @param {Object} check Check object.
 * @param {Object} settings Extended settings object with sessionId.
 */
export const getDiscounts = (accountId, tenderTypeId, check, settings) => {
  const products = LocalCache.getProducts();
  const terminal = LocalCache.getTerminal()._data;
  const revCenter = LocalCache.getRevCenter()._data;

  const req = {
    accountNumber: [accountId],
    revenueCenterID: revCenter.ExternalId,
    propertyCode: revCenter.Property.ExternalId,
    terminalId: terminal.ExternalId,
    checkNo: check.number,
    transactionDate: new Date(),
    items: check.items.map(item => ({
      itemCode: products[item.productId]._data.ExternalId,
      itemQty: item.quantity,
      perItemCost: products[item.productId].price,
    })),
  };

  // Since HA wants the entire item object back for redemption with the appropriate discounts,
  // save each item with a single discount to the _data field. We will only ever apply 1 discount
  // at a time. Redemption will simply send _data field.

  return post('getplayerdiscounts', req, settings)
    .then(res => res.data)
    .then(data => data.items
      .reduce((acc, item) => {
        item.discounts.forEach(disc => acc.push({
          _data: {
            ...item,
            discounts: [disc],
          },
          tenderTypeId,
          id: disc.discountID,
          name: disc.discountName,
          amount: disc.discountAmount,
        }));

        return acc;
      }, [])
    );
};

/**
 * Get a redemption id to redeem the discount. You must call endSession for this discount to appear on the next request.
 * @param {Object} Check Check object.
 * @param {Object} discount Discount object.
 * @param {Object} settings Extended settings object with sessionId.
 */
export const getRedemptionId = (check, discount, settings) => {
  const terminal = LocalCache.getTerminal()._data;
  const revCenter = LocalCache.getRevCenter()._data;

  const req = {
    revenueCenterID: revCenter.ExternalId,
    propertyCode: revCenter.Property.ExternalId,
    terminalId: terminal.ExternalId,
    checkNo: check.number,
    transactionDate: new Date(),
    items: [discount._data],  // See getPlayerDiscounts()
  };

  return post('lockdiscount', req, settings)
    .then(res => res.data)
    .then(data => ({ redemptionId: data.redemptionID }));
};

/**
 * Redeem discount using redemption identifier.
 * @param {string} redemptionID Redemption identifier.
 * @param {Object} check Check object.
 * @param {Object} settings Extended settings object with sessionId.
 */
export const redeemDiscount = (redemptionID, check, settings) => {
  const terminal = LocalCache.getTerminal()._data;
  const revCenter = LocalCache.getRevCenter()._data;

  const req = {
    redemptionID,
    revenueCenterID: revCenter.ExternalId,
    propertyCode: revCenter.Property.ExternalId,
    terminalId: terminal.ExternalId,
    checkNo: check.number,
    transactionDate: new Date(),
  };

  return post('redeemdiscount', req, settings)
    .then(res => res.data);
};