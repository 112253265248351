import React, {useEffect} from 'react';
import TapGestureLottie from '../lotties/TapGestureLottie';
import './TapAnywhereOverlay.scss';
import classNames from 'classnames';

const TapAnywhereOverlay = ({ show, onTap }) => {
  const tapAnywhereClassNames = classNames({
    'tap-anywhere-to-continue': true,
    'tap-anywhere-to-continue--visible': show,
  });

  useEffect(() => {
    const handleClick = () => {
      if (!show) {
        return;
      }
      
      onTap();
    };
    
    document.addEventListener('click', handleClick);
    
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [show]);
  
  return (
    <>
      {show &&
        <div className={tapAnywhereClassNames}>
          <div className="d-flex align-items-center tap-anywhere-to-continue__content">
            <div className="w-25">
              <TapGestureLottie color="#ffffff" />
            </div>
            <i>
              Tap anywhere to continue
            </i>
          </div>
        </div>
      }
    </>
  );
};

export default TapAnywhereOverlay;