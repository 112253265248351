import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ToggleContainer = styled.span`
& > label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
  cursor: pointer;
}

& > label::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

& > input:checked + .switch::after {
  left : 20px;
}

& > input:checked + .switch {
  background-color: ${props => props.theme.primaryColor};
}

& > input {
  display : none;
}
`;

const Toggle = ({
  checked,
  onChange,
}) => (
  <ToggleContainer>
    <input type="checkbox" id="toggle" className="checkbox" checked={checked} onChange={onChange} />
    <label htmlFor="toggle" className="switch"></label>
  </ToggleContainer>
);

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Toggle;