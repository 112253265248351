import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Spinner from './Spinner';
import './Loading.scss';

const Loading = ({
  complete,
  icon,
  show,
  text = 'Loading',
}) => (
  <Modal
    show={show}
    centered={true}
    backdrop="static"
    size="sm"
    className="loading"
  >
    <Modal.Body>
      <div className="loading__body">
        <div className="loading__anim">
          <Spinner complete={complete} />
          {icon && <i className={`fa fa-${icon} loading__icon`} />}
        </div>
        <p className="loading__text">{text}</p>
      </div>
    </Modal.Body>
  </Modal> 
);

Loading.propTypes = {
  complete: PropTypes.bool,
  icon: PropTypes.string,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default Loading;