import React from 'react';
import Lottie from '../components/Lottie';
import {FailureCrossLottieData} from '../../common/images';

// See lottie animation at: https://iconscout.com/lottie-animation/failed-5461125
const FailureCrossLottie = () => {
  return (
    <Lottie animationData={FailureCrossLottieData} />
  );
};

export default FailureCrossLottie;