import React, {useContext, useState} from 'react';
import { Console } from 'console-feed';
import { Accordion, Card, Button } from 'react-bootstrap';
import {ConsoleLogContext} from '../common/providers/ConsoleLogProvider';
import {getTerminalSettingAsBool} from '../common/TerminalHelpers';
import {MetadataContext} from '../common/providers/MetadataProvider';
import * as TerminalSettings from '../common/TerminalSettings';

const Unauthorized = () => {
  const [open, setOpen] = useState(false);
  
  const { logs } = useContext(ConsoleLogContext);
  
  const { terminal } = useContext(MetadataContext);
  
  const showAdvanced = terminal
    ? getTerminalSettingAsBool(terminal, TerminalSettings.IS_DEVELOPER_MODE)
    : true;

  const toggleAccordion = () => {
    setOpen(!open);
  };
  
  const copyLogs = async () => {
    try {
      let logCopy = '';

      logs.forEach(log => {
        logCopy += `${log.method}: ${JSON.stringify(log.data)}\n`;
      });
    
      await navigator.clipboard.writeText(logCopy);
    } catch (err) {
      console.error('Failed to copy logs', err);
    }
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="text-muted text-center">
        <h3 className="text-muted">Unauthorized</h3>
        <p>Your session has expired. See server.</p>
        <small>You may close this window</small>
      </div>
      {showAdvanced &&
        <>
          <Button variant="link" className="mt-5 m-2" onClick={toggleAccordion}>
            {open ? 'Hide Advanced' : 'Show Advanced'}
          </Button>
          <Accordion className="w-100">
            <Accordion.Collapse in={open} eventKey={0}>
              <div>
                <Card>
                  <Card.Header>
                    <Button variant="link" onClick={copyLogs}>
                      Copy Logs
                    </Button>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Console logs={logs} variant="dark" />
                  </Card.Body>
                </Card>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </>
      }
    </div>
  );
};

export default Unauthorized;
