import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { withSettings } from '../providers';

const NavButtonGroup = ({
  buttons,
  settings,
}) => {
  const { primaryColor } = settings;

  return buttons.map(({ name, icon, onClick, primary, ...rest }, index) => (
    <Button
      key={index}
      className="btn-thick rounded-pill ml-4 border-0"
      variant="dark"
      style={{
        backgroundColor: primary ? primaryColor : null,
      }}
      onClick={onClick}
      {...rest}
    >
      <i className={`fa fa-${icon} mr-3`}></i>
      {name}
    </Button>
  ));
};

NavButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      primary: PropTypes.bool,
    })
  ),
};

export default withSettings(NavButtonGroup);