import React from 'react';
import LoadingCircleLottie from '../lotties/LoadingCircleLottie';
import FailureCrossLottie from '../lotties/FailureCrossLottie';
import SuccessCheckmarkLottie from '../lotties/SuccessCheckmarkLottie';

export const ResultStates = {
  success: 'success',
  failure: 'failure',
};

const LoadingWithResultIndicator = ({ isComplete, resultState }) => {
  
  const getResultLottie = () => {
    switch (resultState) {
      case ResultStates.success:
        return <SuccessCheckmarkLottie />;
      case ResultStates.failure:
        return <FailureCrossLottie />;
      default:
        return null;
    }
  };
  
  return (
    <>
      {!isComplete ? (
        <LoadingCircleLottie />
      ) : (
        getResultLottie()
      )}
    </>
  );
};

export default LoadingWithResultIndicator;