import React from 'react';
import Lottie from '../components/Lottie';
import {SuccessCheckmarkLottieData} from '../../common/images';

// See lottie animation at: https://iconscout.com/lottie-animation/success-5461130
const SuccessCheckmarkLottie = () => {
  return (
    <Lottie animationData={SuccessCheckmarkLottieData} />
  );
};

export default SuccessCheckmarkLottie;