import React from 'react';
import { compose } from 'recompose';
import { Loading } from '../common/components';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';

import './Welcome.scss';



const Welcome = ({
  addGuest,
  createNewCheck,
  curMenuId,
  history,
  menus,
  refreshMetadata,
  updateCheck,
}) => {
  React.useEffect(() => {
    refreshMetadata();

    history.push(`/menus/${curMenuId}`);

  }, [createNewCheck, curMenuId, history, menus, refreshMetadata, updateCheck]);

  return <Loading show={true} />;
};

export default compose(
  withCheck,
  withSettings,
  withMetadata,
  withToast,
)(Welcome);