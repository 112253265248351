import React from 'react';
import ConnectStartOrder from './ConnectStartOrder';
import RevCenterList from './RevCenterList';
import * as Metadata from '../common/providers/MetadataProvider/Metadata';
import { LocalCache } from '../common';
import { PAYMENT_TYPE_CC } from '../common/Constants';
import { Loading } from '../common/components';
import { SettingsContext } from '../common/providers/SettingsProvider';
import { CheckContext } from '../common/providers/CheckProvider/CheckProvider';
import { MetadataContext } from '../common/providers/MetadataProvider';

const SettingsDefault = {
  token: '',
  primaryColor: "#C0412D",
  revCenterName: '',
};

const Connect = ({
  location,
  history,
}) => {
  const params = new URLSearchParams(location.search);
  const key = params.get('key');
  
  const { setSettings, resetSettings } = React.useContext(SettingsContext);
  const { updateCheck, createNewCheck } = React.useContext(CheckContext);
  const { 
    refreshMetadata, 
    resetMetadata, 
    revCenter, revCenterGroup, 
    curMenuId, tenderTypes 
  } = React.useContext(MetadataContext);

  const [showHome, setShowHome] = React.useState(false);
  const [token, setToken] = React.useState();
  const [isInitialized, setInitialized] = React.useState(false);
  const supportedTenderTypes = tenderTypes?.supported || {};
  const canOrder = supportedTenderTypes[PAYMENT_TYPE_CC];

  if (isInitialized && !canOrder) {
    console.warn(`Missing supported CC tender type ${PAYMENT_TYPE_CC}`);
  }

  const handleSelectRevCenter = async (rev) => {
    if (rev.revCenterId === revCenter.id) {
      setShowHome(true);
      return;
    }

    // Reset the metdata and settings so that it can
    // refetch the metadata of the new rev center if it
    // is on the same app (url).  Otherwise, it will think the
    // data has already been fetched, skip fetching metadata,
    // and redirect to the order page.
    await resetMetadata();
    resetSettings();

    window.location.href = rev.orderUrl;
  };

  React.useEffect(() => {
    const validateKey = async () => {
      try {
        const { token } = await Metadata.verifyKey(key);
        setToken(token);

        if (!token) {
          setInitialized(true);
        }
      } catch (err) {
        console.error(err);
        history.push('/error');
      }
    };

    validateKey();
  }, [key, history, setSettings]);

  React.useEffect(() => {
    
    const fetchMetadata = async () => {
      try {
        const settings = {
          ...SettingsDefault,
          token,
        };

        LocalCache.setSettings(settings);
        const posTerminalData = await Metadata.callDecodeLambda('load');  
        
        clearCacheIfNewTerminal(posTerminalData);

        // Set meta in temp keys
        await Metadata.setStagingCacheData(posTerminalData);

        // Save token to settings
        setSettings(settings);

        refreshMetadata();

      } catch(err) {
        console.error(err);

        // Reset settings
        setSettings({ ...SettingsDefault });

        history.push('/error');
      }

      setInitialized(true);
    } 

    if (token) {
      fetchMetadata();
    }
  }, [token, history, refreshMetadata, setSettings]);

  function clearCacheIfNewTerminal(metaInfo) {
    const terminalInfo = LocalCache.getTerminal();
    const metaTerminalId = metaInfo.terminal.Id;
    if (!(!!terminalInfo && terminalInfo.id === metaTerminalId)) {
      updateCheck(createNewCheck());
      LocalCache.clear();
    }
  }

  if (!isInitialized) {
    return <Loading show={true} text="Loading" />;
  }

  if (revCenterGroup.revCenters.length && !showHome) {
    return <RevCenterList revCenterGroup={revCenterGroup} onSelect={handleSelectRevCenter} />;
  }

  return <ConnectStartOrder
    isInitialized={isInitialized}
    canOrder={canOrder}
    isClosed={!curMenuId}
    revCenter={revCenter}
  />;
};

export default Connect;