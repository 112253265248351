import React, {useContext} from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import { withCheck } from '../common/providers';
import { ThemedButton, ThemedHeader, ThemedSpinner } from '../common/Themed';
import { PromoList } from '../common/components';
import {getTerminalSettingAsBool} from "../common/TerminalHelpers";
import {MetadataContext} from "../common/providers/MetadataProvider";
import {CheckContext} from "../common/providers/CheckProvider/CheckProvider";


const ConnectStartOrder = ({
  revCenter,
  isInitialized,
  isClosed,
  canOrder,
  history,
}) => {

    const {terminal} = useContext(MetadataContext);
    const handleStartOrderClicked = () => {
        const allowed = getTerminalSettingAsBool(terminal, 'pt-allow-take-out');
        if(allowed){
            history.push('/orderTypeSelection');
        }
        else {
            history.push('/');
        }
    }
    
  if (!revCenter) {
    return (
      <div className="text-center py-4">
        <Spinner animation="border" variant="primary" />
        <p className="text-primary">Loading restaurant data...</p>
      </div>
    );
  }

  return (
    <div className="container py-4">
      {revCenter.heroImageUrl && <img className="rounded mx-auto d-block img-fluid" alt="logo" src={revCenter.heroImageUrl} />}

      {revCenter.landingPageTitle &&
        <ThemedHeader.h1 className="text-center mt-3">{revCenter.landingPageTitle}</ThemedHeader.h1>}

      {isInitialized && canOrder && isClosed &&
        <Alert variant="warning">
          <i className="fa fa-exclamation-triangle" /> {revCenter.closedMessage || 'Outlet is currently not accepting mobile orders at this time.'}
        </Alert>
      }

      <div className="text-center p-4">
        {!isInitialized && <ThemedSpinner animation="border" />}

        {isInitialized && canOrder && !isClosed &&
          <>
            <ThemedButton className="mb-4" onClick={handleStartOrderClicked}>
              Start Order
            </ThemedButton>
            <div className="text-left text-muted">
              <small>* Thoroughly cooking foods of animal origin, such as beef, eggs, lamb, milk, poultry or shellfish reduces the risk of foodborne illness. Young children, elderly and certain individuals with certain health conditions may be at higher risk if these foods are consumed raw or undercooked.</small>
            </div>
          </>
        }

        {!!revCenter.homePromos.length && <PromoList promos={revCenter.homePromos} />}

        {isInitialized && !canOrder &&
          <Alert variant="warning">
            <i className="fa fa-exclamation-triangle mr-2" />
            Additional configuration needed to start order. Please contact an admin.
          </Alert>
        }
      </div>
    </div>
  );
};

export default compose(
  withCheck,
  withRouter,
)(ConnectStartOrder);