import React from 'react';
import PropTypes from 'prop-types';
import * as halo from '../../common/HouseAvantage';
import * as pos from '../../common/PosApi';
import InquiryForm from '../InquiryForm';
import { compose } from 'recompose';
import { withMetadata, withToast } from '../../common/providers';

function HaloInquiryForm({
  check,
  tenderType,
  onConfirm,
  onCancel,
  toast,
}) {
  const [discounts, setDiscounts] = React.useState();
  const [sessionId, setSessionId] = React.useState();

  const handleInquiry = async ({ accountId, pin }) => {
    try {
      const inquiryRequest = {
        accountId,
        pin,
        check,
        tenderTypeId: tenderType.id,
      };

      // Destructure player if player info needs to be shown
      const { sessionId, discounts } = await halo.inquiry(inquiryRequest);

      setSessionId(sessionId);
      setDiscounts(discounts);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  const handlePosting = async (selectedIndex) => {
    try {
      const discount = discounts[selectedIndex];
      const redeemRequest = {
        sessionId,
        check,
        discount,
        orderId: check.id,
        tenderTypeId: tenderType.id,
      };

      const result = await halo.redeem(redeemRequest);
      const transaction = await pos.postTransaction({
        name: discount.name,
        amount: result.amount,
        tip: 0,
        tenderTypeId: tenderType.id,
        check,
        extendedData: null,
      });

      onConfirm(transaction);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  return <InquiryForm
    balance={tenderType.isTaxExempt ? check.taxExemptBalance : check.balance}
    results={discounts}
    tenderType={tenderType}
    onInquiry={handleInquiry}
    onPosting={handlePosting}
    onCancel={onCancel}
  />;
}

HaloInquiryForm.propTypes = {
  check: PropTypes.object.isRequired,
  tenderType: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default compose(
  withMetadata,
  withToast,
)(HaloInquiryForm);