import React from 'react';
import PropTypes from 'prop-types';
import BottomButtonGroup from './BottomButtonGroup';
import { Modal } from 'react-bootstrap';
import './ConfirmCancelModal.scss';

const ConfirmCancelModal = ({
  show,
  message,
  onCancel,
  onConfirm,
}) => {

  const handleCancel = () => onCancel && onCancel();
  const handleConfirm = () => onConfirm && onConfirm();

  const bottomButtons = [
    {
      variant: 'dark',      
      onClick: handleCancel,
      content: 'No',
    },
    {
      primary: true,
      onClick: handleConfirm,
      content: 'Yes',
    }
  ];

  return (
    <Modal
      show={show}
      centered={true}
      onHide={handleCancel}
      className="confirm"
    >
      <Modal.Body>
        <p className="confirm__text">{message}</p>

        <BottomButtonGroup 
          style={{
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: 0,
          }} 
          buttons={bottomButtons} 
      />
      </Modal.Body>
    </Modal>
  );
};

ConfirmCancelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmCancelModal;