import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Badge } from 'react-bootstrap';

const QuantityBadge = ({
  quantity,
}) => (
  <Badge
    pill
    className="rounded-circle"
    variant="light"
    style={{
      color: useTheme().primaryColor,
      padding: '12px 8px',
      width: 40,
    }}
  >
    {quantity > 99 ? '99+' : quantity.toString()}
  </Badge>
);

QuantityBadge.propTypes = {
  quantity: PropTypes.number.isRequired,
};

export default QuantityBadge;