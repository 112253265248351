/**
 * Current menu id.
 */

export const CUR_MENU_ID = 'cur_menu_id';

export const NOTES_DEFAULT_ID = '00000000-0000-0000-0000-000000000000';

/**
 * SETTINGS DETAILS
 */

export const PLAYER_REWARD_URL = '';

export const PLAYER_REWARD_CLIENT_ID = 'client_id';

export const PLAYER_REWARD_AUTHORIZATION_KEY = 'authorization_key';

export const HA_LICENSE_KEY = 'ha_license_key';

/**
 * Current settings storage key.
 */
export const SETTINGS_KEY = 'settings';
export const POLLING_KEY = 'setpolling';
export const PLAYER_ID = '123';

export const CHECK_KEY = 'check';

/**
 * Current menus storage key.
 */
export const MENUS_KEY = 'menus';

/**
 * Cancelled order status.
 */
export const ORDER_STATUS_CANCELLED = 'cancelled';

/**
 * Timeout order status.
 */
export const ORDER_STATUS_TIMEOUT = 'timeout';

/**
 * Error order status.
 */
export const ORDER_STATUS_ERROR = 'error';

/**
 * Credit card payment type.
 */
export const PAY_TYPE_CC = 'cc';

/**
 * Player rewards payment type.
 */
export const PAYMENT_TYPE_PLAYER_REWARD = 'PlayerRewards';

/**
 * Credit card payment type.
 */
export const PAYMENT_TYPE_CC = 'CreditDebit';

/**
 * PMS payment type.
 */
export const PAYMENT_TYPE_PMS = 'PMS';

/**
 * Discount payment type.
 */
export const PAYMENT_TYPE_DISCOUNT = 'Other';

/**
 * Membership payment type (ex: Sightline).
 */
export const PAYMENT_TYPE_MEMBERSHIP = 'Membership';

/**
 * Current product groups storage key.
 */
export const PRODUCT_GROUPS_KEY = 'product_groups';

/**
 * Current products storage key.
 */
export const PRODUCTS_KEY = 'products';

/**
 * Current Exception modifier groups storage key.
 */
export const EXCEPTION_MODIFIERS_GROUPS_KEY = 'exception_modifier_Groups';

/**
 * Current Exception modifiers storage key.
 */
export const EXCEPTION_MODIFIERS_KEY = 'exception_modifiers';

/**
 * Current Forced Modifier Groups storage key.
 */
export const FORCED_MODIFIER_GROUPS_KEY = 'forced_modifier_Groups';

/**
 * Current products storage key.
 */
export const FORCED_MODIFIERS_KEY = 'forced_modifiers';

/**
 * Current payments storage key.
 */
export const TENDER_TYPES_KEY = 'tender_types';

/**
 * Current revenue center storage key.
 */
export const REV_CENTER_KEY = 'rev_center';

/**
 * Revenue center group (food court).
 */
export const REV_CENTER_GROUP_KEY = 'rev_center_group';

/**
 * Current revenue types storage key.
 */
export const REVENUE_TYPES_KEY = 'revenue_types';

/**
 * Current terminal storage key.
 */
export const TERMINAL_KEY = 'terminal';

/**
 * Cancellation prompt text.
 */
export const TEXT_CANCEL = 'Are you sure you want to cancel?';

/**
 * Supported online payment built in tender type.
 */
export const SUPPORTED_ONLINE_PAYMENT = 'FreedomPay';
