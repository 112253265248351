import React from 'react';
import { useLocation } from 'react-router-dom';
import { withMetadata } from './MetadataProvider';

const MatchPathName = {
  home: { reg: /^\/connect$/, title: 'Start Order' },
  menu: { reg: /^\/menus\/.*\/productgroups\/.*/i, title: 'Menu' },
  summary: { reg: /^\/checkout$/i, title: 'Order Summary' },
  pay: { reg: /^\/pay$/i, title: 'Payment' },
  thankyou: { reg: /^\/thankyou$/i, title: 'Confirmation' },
};

/**
 * @typedef AnalyticsData
 * @property {string} title Title of the page.
 */

export const GoogleAnalyticsContext = React.createContext({

  /**
   * @param {AnalyticsData} data Analytics data.
   */

  pushDataLayer: (data) => {},
});

const GAProvider = ({
  revCenter,
  terminal,
  children,
}) => {
  const event = 'dataLayer-initialized';
  const { defaultUserId: server } = terminal || {};
  const {
    propertyCode,
    name,
    brandLabel: brand,
    city: division,
  } = revCenter || {};
  const location = useLocation();

  /**
   * @param {AnalyticsData} data Analytics data.
   */

  const pushDataLayer = React.useCallback((data) => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    const { title } = data;
    const pageTitle = `${name} | ${title}`;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      server,
      brand,
      propertyCode,
      division,
      pageTitle,
    });
  }, [propertyCode, brand, division, name, server]);

  React.useEffect(() => {
    const matchedKey = Object.keys(MatchPathName).find(key =>
      MatchPathName[key].reg.test(location.pathname)
    );

    if (matchedKey) {
      const path = MatchPathName[matchedKey];
      pushDataLayer({ title: path.title });
    }
  }, [location, pushDataLayer]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Dev mode: Google Analytics will not be active');
    }
  }, []);

  return (
    <GoogleAnalyticsContext.Provider value={{ pushDataLayer }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export const GoogleAnalyticsProvider = withMetadata(GAProvider);

export const useGoogleAnalytics = () => React.useContext(GoogleAnalyticsContext);
