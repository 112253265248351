import React from 'react';
import PinPad from '../../common/components/PinPad';
import {useHistory} from 'react-router-dom';
import {BottomButtonGroup} from '../../common/components';

const PlayerCardPin = ({ onSubmit }) => {
  
  const history = useHistory();
  
  const handleSkipSwipeClicked = () => {
    history.push('/pay');
  };
  
  return (
    <>
      <div className="player-swipe m-0 h-100">
        <h1 className="player-swipe__title p-0 text-center">
          Enter Pin Number
        </h1>
        <div className="player-swipe__pin-pad p-0" style={{display: 'flex', justifyContent: 'center'}}>
          <PinPad maxDisplayLength={10} onSubmit={onSubmit}/>
        </div>
        <div className="mb-5">
          <BottomButtonGroup buttons={[{
            name: 'Skip to Payment',
            primary: false,
            onClick: handleSkipSwipeClicked,
          }]}/>
        </div>
      </div>
    </>
  );
};

export default PlayerCardPin;