import React, {useContext, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Route, Redirect, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { GoogleAnalyticsProvider, withCheck, withMetadata, withSettings } from './common/providers';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Checkout } from './checkout';
import { Thankyou } from './thankyou';
import { Welcome } from './home';
import {OrderTypeSelection} from './orderTypeSelection';
import { Menu, ProductGroupList } from './ordering';
import { Connect } from './auth';
import { Pay } from './pay';
import { Unauthorized } from './error';
import { AuthRoute, Loading, MenuTopNav } from './common/components';
import { ToastProvider } from './common/providers';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import ReceiptViewer from './receipt/ReceiptViewer';
import {TimeoutContext} from './common/providers/TimeoutProvider';
import {MetadataContext} from './common/providers/MetadataProvider';
import {SettingsContext} from './common/providers/SettingsProvider';
import PlayerCardSwipePage from './ordering/playerCardSwiping/PlayerCardSwipePage';
import {getTerminalSetting} from './common/TerminalHelpers';
import {BACKGROUND_IMAGE, PLAYER_SWIPE_CARD_BACKGROUND_IMAGE} from './common/TerminalSettings';
import * as TerminalSettings from './common/TerminalSettings';

function App({
  check,
  location,
  settings,
  revCenter,
}) {
  const [backgroundImage, setBackgroundImage] = React.useState('');
  const [showKickedOut, setShowKickedOut] = React.useState(false);
  
  const { isTimeoutReached, resetTimeoutTimer } = useContext(TimeoutContext);
  const { resetMetadata, terminal } = useContext(MetadataContext);
  const { resetSettings } = useContext(SettingsContext);

  const timeoutRedirectUrl = getTerminalSetting(terminal, TerminalSettings.LANDING_PAGE_URL)

  const finalizeOrderTimeoutUrl = getTerminalSetting(terminal, TerminalSettings.FINALIZE_ORDER_REDIRECT_URL);

  React.useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', revCenter?.color || '#C0412D');
  }, []);

  React.useEffect(() => {
    if (!backgroundImage) {
      document.body.style.backgroundColor = '#f8f9fa';
      document.body.style.backgroundImage = '';
    } else {
      document.body.style.backgroundColor = '';
      document.body.style.backgroundImage = `url('${backgroundImage}')`;
    }
  }, [backgroundImage]);
  
  React.useEffect(() => {
    const playerSwipeBackgroundImage = getTerminalSetting(terminal, PLAYER_SWIPE_CARD_BACKGROUND_IMAGE);

    if (location.pathname === '/playerswipe' && playerSwipeBackgroundImage) {
      setBackgroundImage(playerSwipeBackgroundImage);
    } else {
      setBackgroundImage(getTerminalSetting(terminal, BACKGROUND_IMAGE) || '');
    }
  }, [terminal, location]);

  React.useEffect(() => {
    if (!isTimeoutReached) {
      return;
    }

    if (location.pathname === '/' || 
      location.pathname === '/settings' ||
      location.pathname.includes('/connect') ||
      location.pathname.includes('/error')) {
      return;
    }
    
    setShowKickedOut(true);
    setTimeout(() => {
      resetTimeoutTimer();
      resetMetadata();
      resetSettings();
      setShowKickedOut(false);
      
      if (location.pathname.includes('thankyou') && finalizeOrderTimeoutUrl) {
        window.location.href = finalizeOrderTimeoutUrl;
      } else if (timeoutRedirectUrl) {
        window.location.href = timeoutRedirectUrl;
      }
    }, 2000);
    
  }, [isTimeoutReached]);

  // TODO: refactor components to use styled components
  //       theme to prevent injecting settings
  //       everywhere.
  const theme = {
    primaryColor: revCenter?.color || '#C0412D',
  };

  const hasCustomerAccounts = check.customerAccounts && Object.keys(check.customerAccounts).length > 0;
  const shouldShowPlayerSwipe = !terminal || (terminal._data.AccountInquiryDefaultTenderTypeId && !hasCustomerAccounts);

  return (
    <GoogleAnalyticsProvider>
      <div className="app">

        <Helmet>
          <title>{revCenter?.name || 'Kiosk'}</title>
        </Helmet>

        <ThemeProvider theme={theme}>
          <ToastProvider>
            <Loading
              icon="sign-out-alt"
              show={showKickedOut}
              text="Timed Out"
            />

            <MenuTopNav />

            <div className="app__contentcontainer">

              <Switch>

                <AuthRoute exact path="/" component={Welcome} valid={!!settings.token} />

                <Route path="/connect" component={Connect} />

                <AuthRoute exact path="/orderTypeSelection" component={OrderTypeSelection}/>
                

                <AuthRoute
                  exact
                  path="/menus/:menuId"
                  component={ProductGroupList}
                  validations={[
                    {
                      valid: !!settings.token,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/playerswipe',
                    },
                  ]}
                />

                <AuthRoute
                  path="/menus/:menuId/productgroups/:productGroupId"
                  component={Menu}
                  validations={[
                    {
                      valid: !!settings.token && !!check.guests.length,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/playerswipe',
                    },
                  ]}
                />

                <AuthRoute
                  path="/checkout"
                  component={Checkout}
                  validations={[
                    {
                      valid: !!settings.token && !!check.totalQuantity,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/playerswipe',
                    },
                  ]}
                />

                <AuthRoute
                  path="/playerswipe"
                  component={PlayerCardSwipePage}
                  validations={[
                    // If a customer account has already been assigned redirect to payment page
                    {
                      valid: shouldShowPlayerSwipe,
                      redirectUrl: '/pay',
                    }
                  ]}
                />

                <AuthRoute
                  path="/pay"
                  component={Pay}
                  valid={!!settings.token && !!check.totalQuantity}
                  redirectUrl="/"
                />

                <AuthRoute
                  path="/thankyou"
                  component={Thankyou}
                  validations={!!settings.token}
                  redirectUrl="/" />

                <Route path="/receipt/:revenueCenterId/:orderId/:receiptId" component={ReceiptViewer} />

                <Route path="/error" component={Unauthorized} />
                <Redirect path="*" to="/" />

              </Switch>

            </div>

          </ToastProvider>
        </ThemeProvider>
      </div>
    </GoogleAnalyticsProvider>
);
}

export default compose(
withCheck,
withMetadata,
  withRouter,
  withSettings,
)(App);