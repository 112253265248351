import React from 'react';
import PropTypes from 'prop-types';
import { sortItems } from '../common/helpers';
import ProductSelectionGroup from './ProductSelectionGroup';

const Modifiers = ({
  modGroupsOrderByWeight,
  product,
  forcedModifiersGroups,
  productGroup,
  exceptionModifiersGroups,
  onForcedModToggle,
  onExceptionModToggle,
}) => {

  const { forcedModifiersGroups : productForcedModGroups } = product;
  const { exceptionModifierGroups: productExceptionModGroups } = productGroup;
  
  const [selectedForcedMods, setSelectedForcedMods] = React.useState({});
  const [expandedForcedModifierIndex, setExpandedForcedModifiersIndex] = React.useState(0);
  const [expandedExceptionModifierIndex, setExpandedExceptionModifiersIndex] = React.useState(
    Object.keys(productForcedModGroups).length ? -1 : 0
  );

  const handleForcedModClick = (selected, groupId, modId, mod) => {
    const group = selectedForcedMods[groupId] || {};

    const newSelectedForcedMods = {
      ...selectedForcedMods,
      [groupId]: {
        ...group,
        [modId]: true,  // The value doesn't matter, just the key
      },
    };

    if (!selected) {
      delete newSelectedForcedMods[groupId][modId];
    }

    setSelectedForcedMods(newSelectedForcedMods);

    onForcedModToggle(groupId, modId, mod);
  };
  
  const handleModifierRequiredSelectionsReached = (index, minSelections, maxSelections) => {
    // If the user could select more items than the minimum, then do not expand the next group automatically
    if (minSelections < maxSelections)
      return;
    
    setExpandedForcedModifiersIndex(index + 1);
  };
  
  const handleForcedModifierAccordionHeaderClick = (index) => {

    setExpandedExceptionModifiersIndex(-1);
    
    if (index === expandedForcedModifierIndex) {
      setExpandedForcedModifiersIndex(-1);
      
      return;
    }
      
    setExpandedForcedModifiersIndex(index);
  };

  const handleExceptionModifierAccordionHeaderClick = (index) => {

    setExpandedForcedModifiersIndex(-1);
    
    if (index === expandedExceptionModifierIndex) {
      setExpandedExceptionModifiersIndex(-1);

      return;
    }

    setExpandedExceptionModifiersIndex(index);
  };

  /**
   * Sorted forced mod groups for product.
   */
  const sortedProductForcedModGroups = React.useMemo(() => {
    const modGroups = Object.keys(productForcedModGroups)
      .map(gpId => ({
        id: gpId,
        name: productForcedModGroups[gpId].name,
        position: productForcedModGroups[gpId].position,
        data: productForcedModGroups[gpId],
      }));

    return sortItems(modGroups, modGroupsOrderByWeight)
      .reduce((prev, group) => {
        return {
          ...prev,
          [group.id]: group.data,
        };
      }, {});
  }, [productForcedModGroups, modGroupsOrderByWeight]);

  /**
   * Sorted exception mod groups for product which is attached to prod group.
   */
  const sortedProductExceptionModGroups = React.useMemo(() => {
    const modGroups = Object.keys(productExceptionModGroups)
      .map(gpId => ({
        id: gpId,
        name: productExceptionModGroups[gpId].name,
        position: productExceptionModGroups[gpId].position,
        data: productExceptionModGroups[gpId],
      }));

    return sortItems(modGroups, modGroupsOrderByWeight)
      .reduce((prev, group) => {
        return {
          ...prev,
          [group.id]: group.data,
        };
      }, {});
  }, [productExceptionModGroups, modGroupsOrderByWeight]);

  /**
   * Sorted forced mod group catalog.
   */
  const sortedForcedModifierGroups = React.useMemo(() => {

    return Object.keys(forcedModifiersGroups)
      .map(fmgKey => ({
        id: fmgKey,
        name: forcedModifiersGroups[fmgKey].name,
        orderByWeight: forcedModifiersGroups[fmgKey].orderByWeight,
        modifiers: Object.keys(forcedModifiersGroups[fmgKey].forcedModifiers)
          .map(fmKey => ({
            id: fmKey,
            name: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey].name,
            position: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey].position,
            modifier: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey],
          })),
      }))
      .reduce((prev, group) => {
        const forcedModifiers = sortItems(group.modifiers.slice(), group.orderByWeight)
          .reduce((prev, mod) => ({
            ...prev,
            [mod.id]: mod.modifier,
          }), {});

        return {
          ...prev,
          [group.id]: {
            ...forcedModifiersGroups[group.id],
            forcedModifiers,
          },
        };
      }, {});

  }, [forcedModifiersGroups]);

  /**
   * Sorted exception mod group catalog.
   */
  const sortedExceptionModGroups = React.useMemo(() => {
    return Object.keys(exceptionModifiersGroups)
      .map(emgKey => ({
        id: emgKey,
        name: exceptionModifiersGroups[emgKey].name,
        orderByWeight: exceptionModifiersGroups[emgKey].orderByWeight,
        modifiers: Object.keys(exceptionModifiersGroups[emgKey].exceptionModifier)
          .map(emKey => ({
            id: emKey,
            name: exceptionModifiersGroups[emgKey].exceptionModifier[emKey].name,
            position: exceptionModifiersGroups[emgKey].exceptionModifier[emKey].position,
            modifier: exceptionModifiersGroups[emgKey].exceptionModifier[emKey],
          })),
      }))
      .reduce((prev, group) => {
        const exceptionModifier = sortItems(group.modifiers.slice(), group.orderByWeight)
          .reduce((prev, mod) => ({
            ...prev,
            [mod.id]: mod.modifier,
          }), {});

        return {
          ...prev,
          [group.id]: {
            ...exceptionModifiersGroups[group.id],
            exceptionModifier,
          },
        };
      }, {});

  }, [exceptionModifiersGroups]);

  return (
    <div style={{backgroundColor: '#ededed'}}>
      {!!Object.keys(sortedProductForcedModGroups).length &&
        <>
          {Object.keys(sortedProductForcedModGroups).map((fmGrpkey, index) =>
            <div key={fmGrpkey} className="my-2">
              <ProductSelectionGroup
                isExpanded={index === expandedForcedModifierIndex}
                onRequiredSelectionsReached={() => handleModifierRequiredSelectionsReached(index, sortedForcedModifierGroups[fmGrpkey].min, sortedForcedModifierGroups[fmGrpkey].max)}
                onHeaderClick={() => handleForcedModifierAccordionHeaderClick(index)}
                minimumSelections={sortedForcedModifierGroups[fmGrpkey].min}
                maximumSelections={sortedForcedModifierGroups[fmGrpkey].max}
                title={sortedForcedModifierGroups[fmGrpkey].name}
                selectableProducts={sortedForcedModifierGroups[fmGrpkey].forcedModifiers}
                onChange={(id, isChecked) => handleForcedModClick(isChecked, fmGrpkey, id, sortedForcedModifierGroups[fmGrpkey].forcedModifiers[id])} />
            </div>
          )}
        </>
      }

      {!!Object.keys(sortedProductExceptionModGroups).length &&
        <>
          {Object.keys(sortedProductExceptionModGroups).map((exModGrpkey, index) =>
            <div key={exModGrpkey} className="exception-modifier-group my-2">
              <ProductSelectionGroup
                isExpanded={index === expandedExceptionModifierIndex}
                onHeaderClick={() => handleExceptionModifierAccordionHeaderClick(index)}
                minimumSelections={0}
                maximumSelections={Object.keys(sortedExceptionModGroups[exModGrpkey].exceptionModifier).length}
                title={sortedExceptionModGroups[exModGrpkey].name}
                selectableProducts={sortedExceptionModGroups[exModGrpkey].exceptionModifier}
                onChange={(id) => onExceptionModToggle(exModGrpkey, id, exceptionModifiersGroups[exModGrpkey].exceptionModifier[id])}/>
            </div>
          )}
        </>
      }
    </div>
  );
};

Modifiers.propTypes = {
  modGroupsOrderByWeight: PropTypes.bool.isRequired,
  forceModifiersGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    forcedModifiers: PropTypes.object.isRequired,
  }),
  onForcedModToggle: PropTypes.func.isRequired,
  onExceptionModToggle: PropTypes.func.isRequired,
};

export default Modifiers;