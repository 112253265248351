import * as Cache from './LocalCache';

export const init = (payload) => {
  const { token } = Cache.getSettings();
  const { id: profitCenterId } = Cache.getRevCenter();
  const url = process.env.REACT_APP_FCC_INIT_URL;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
      profitCenterId,
    })
  })
  .then(response => {
    if (!response.ok) {
      throw Error(response.statusText || 'An error has occurred');
    }

    return response.json();
  });
};

export const pay = async (request) => {
  const { token } = Cache.getSettings();
  const { id: profitCenterId } = Cache.getRevCenter();
  const url = process.env.REACT_APP_FCC_PAY_URL;

  const fetchRequest = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...request,
      profitCenterId,
    }),
  };

  try {
    const response = await fetch(url, fetchRequest);

    if (response.ok) {
      return response.json();
    }

    // Handle error

    let message = null;

    // Payment specific error
    if (response.status === 502) {
      const paymentError = await response.json();
      message = paymentError.message;
    }

    throw Error(message || response.statusText || 'Payment error. Try again in a few minutes.');
  } catch (err) {
    throw err;
  }
};