import React from 'react';
import { LocalCache } from '..';

//alert(SW_TOKEN);
/**
 * @typedef {Object} TierDiscount
 * @property {string} name The tier name.
 * @property {number} amount The flat discount amount.
 */

/**
 * @typedef {Object} Settings
 * @property {string} url [Required] Url to the POS provider API (SkyWire OData).
 * @property {string} terminalId [Required] The terminal identifier returned as a result of terminal registration.
 * @property {string} token [Required] The Bearer access token returned as a result of terminal registration.
 * @property {string} message [Required] The text shown on the Welcome screen.
 * @property {string} cardSwipeRegex [Required] The regex that matches player id from a card swipe blob. Default: Digits from the second track
 * @property {number} timeout [Required] The kiosk idle timeout in seconds. Default: 150
 * @property {string} primaryColor [Required] The primary hex color value. Default: #007bff
 * @property {string} compFilter Regex pattern to filter comps returned for the player.
 * @property {TierDiscount[]} tierDiscounts Configured tier discounts to be applied by matching the player's tier level.
 */
 // debugger;
const DEFAULT_SETTINGS = {
  "timeout":150,
  "primaryColor":"#007bff"
}


/**
 * Settings context.
 */
const SettingsContext = React.createContext();

const SettingsProvider = ({ children }) => {
  const [curSettings, setCurSettings] = React.useState(LocalCache.getSettings() || DEFAULT_SETTINGS);

  const setSettings = React.useCallback((settings) => {
    LocalCache.setSettings(settings);
    setCurSettings(settings);
  }, []);

  const resetSettings = React.useCallback(() => {
    LocalCache.setSettings(DEFAULT_SETTINGS);
    setCurSettings(DEFAULT_SETTINGS);
  }, []);

  const value = {
    resetSettings,
    setSettings,
    settings: curSettings,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider> 
  );
};

const withSettings = Component => props => (
  <SettingsContext.Consumer>
    {value => <Component {...value} {...props} />}
  </SettingsContext.Consumer>
)

export default SettingsProvider;
export {
  DEFAULT_SETTINGS,
  SettingsProvider,
  SettingsContext,
  withSettings,
};