import {getTerminalSetting} from './TerminalHelpers';
import * as TerminalSettings from './TerminalSettings';

export const HALO_POINTS_BUILT_IN_TENDER_TYPE = 'HouseAdvantagePoints';
export const HALO_COMP_BUILT_IN_TENDER_TYPE = 'HouseAdvantageComps';
export const HALO_COUPON_BUILT_IN_TENDER_TYPE = 'HouseAdvantageCoupon';

export const isHalo = (tender) => {
    return tender.BuiltInTenderType === HALO_POINTS_BUILT_IN_TENDER_TYPE ||
        tender.BuiltInTenderType === HALO_COMP_BUILT_IN_TENDER_TYPE ||
        tender.BuiltInTenderType === HALO_COUPON_BUILT_IN_TENDER_TYPE;
};

export const getCardSwipeRegex = (terminal, tender) => {
    if (isHalo(tender))
        return getTerminalSetting(terminal, TerminalSettings.HOUSE_ADVANTAGE_TERMINAL_SETTING_NAME);
    
    return null;
};