import React from 'react';
import PlayerCardSwipe from './PlayerCardSwipe';
import "./PlayerCardSwipePage.scss";
import {MetadataContext} from '../../common/providers/MetadataProvider';
import {getCardSwipeRegex} from '../../common/TenderTypeHelpers';
import PlayerCardPin from './PlayerCardPin';
import {CheckContext} from '../../common/providers/CheckProvider/CheckProvider';
import {PosApi} from '../../common';
import LoadingWithResultModal from '../../common/modals/LoadingWithResultModal';
import {ResultStates} from '../../common/components/LoadingWithResultIndicator';
import {useHistory} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import PlayerManualAccountEntry from './PlayerManualAccountEntry';

const PlayerCardSwipePage = () => {
  const [showCardSwipe, setShowCardSwipe] = React.useState(!isMobile);
  const [showManualAccountEntry, setShowManualAccountEntry] = React.useState(isMobile);
  const [showEnterPin, setShowEnterPin] = React.useState(false);
  
  const [showVerifyingModal, setShowVerifyingModal] = React.useState(false);
  const [isPostingAccount, setIsPostingAccount] = React.useState(false);
  const [isPostingSuccessful, setIsPostingSuccessful] = React.useState(true);
  const [postingResultMessage, setPostingResultMessage] = React.useState('');
  const [accountNumberEntry, setAccountNumberEntry] = React.useState('');
  
  const { terminal, tenderTypes } = React.useContext(MetadataContext);
  const { check, updateCheck } = React.useContext(CheckContext);
  const history = useHistory();

  const tenderType = Object.keys(tenderTypes)
    .map(id => tenderTypes[id])
    .find(tt => tt.id === terminal._data.AccountInquiryDefaultTenderTypeId);
  
  const isPinRequired = tenderType._data.ProcessorConfiguration.RequirePin;
  const cardSwipeRegex = getCardSwipeRegex(terminal, tenderType._data.Tender);
  
  const handleCardSwipe = async (swipeInput) => {
    try {
      await handleAccountEntrySubmit(swipeInput);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleAccountEntrySubmit = async (accountNumber) => {
    try {
      setAccountNumberEntry(accountNumber);

      if (!isPinRequired) {
        await inquireAndPostAccount(accountNumber, '');

        return;
      }

      setShowEnterPin(true);
      setShowManualAccountEntry(false);
      setShowCardSwipe(false);
    } catch (error) {
      console.error(error);
    }
  };
  
  const inquireAndPostAccount = async (accountId, pin) => {
    let isPostSuccessful = false;
    let account;
    
    try {
      setIsPostingAccount(true);
      setShowVerifyingModal(true);
      
      account = await inquireAccountOrThrow(accountId, pin);
      
      const order = await postCustomerAccountOrThrow(account);
      
      isPostSuccessful = true;

    } catch (error) {
      isPostSuccessful = false;
      
      console.error(error);
    } finally {
      setIsPostingAccount(false);

      // If posting was successful, wait for a moment to show success animation before closing.
      // Note: A failed posting will not automatically close the modal and requires user interaction.
      if (isPostSuccessful) {
        setTimeout(() => {
          setShowVerifyingModal(false);

          addCustomerAccountToCheck(account);
          
          history.push("/pay");
        }, 1200);
      }
    }
  };
  
  const addCustomerAccountToCheck = (account) => {
    const customerAccount = {
      accountId: account.accountId,
      displayName: account.displayName,
      firstName: account.firstName,
      lastName: account.lastName,
      tenderTypeId: tenderType.id,
      checkNumber: 1,
      canPost: account.canPost,
      cardNumber: account.cardNumber,
    };

    // Update the check with the new customer account
    updateCheck({
      ...{
        customerAccounts: {
          ...check.customerAccounts,
          [account.accountId]: customerAccount
        },
      },
    }); 
  };
  
  const inquireAccountOrThrow = async (accountId, pin) => {
    try {
      const inquiry = {
        OrderId: check.id,
        TenderTypeId: tenderType.id,
        AccountId: accountId,
        Pin: pin,
        TerminalId: terminal.id,
      };

      const accounts = await PosApi.postInquiry(inquiry);

      if (accounts.length !== 1)
        throw new Error(`Player details could not be found. Found ${accounts.length} accounts.`);
      
      setIsPostingSuccessful(true);
      
      return accounts[0];
    }
    catch (error) {
      setIsPostingSuccessful(false);
      
      if (isPinRequired) {
        setPostingResultMessage('Oops! Invalid PIN. Please double-check and try again.');
      } else {
        setPostingResultMessage('Oops! We weren\'t able to verify your card. Please try again or ask a server for help.');
      }
      
      throw error;
    }
  };
  
  const postCustomerAccountOrThrow = async (account) => {
    try {
      const postCustomerAccountRequest = {
        orderId: check.id,
        tenderTypeId: tenderType.id,
        customerName: account.displayName,
        accountId: account.accountId,
        checkNumber: 1,
      };
      
      return await PosApi.postCustomerAccount(postCustomerAccountRequest);
    } catch (error) {
      setIsPostingSuccessful(false);
      setPostingResultMessage('Oops! We lost connection. Please try again or ask a server for help.');
      
      throw error;
    }
  };
  
  const handlePinSubmit = async (pin) => {
    try {
      await inquireAndPostAccount(accountNumberEntry, pin);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleTapToContinue = () => {
    setShowVerifyingModal(false);
  };
  
  const getInquiringResultState = () => {
    if (isPostingSuccessful) {
      return ResultStates.success;
    } else {
      return ResultStates.failure;
    }
  };
  
  return (
    <>
      <div className="player-swipe-page h-100 m-0">
        <div className="player-swipe-page__content-container p-0">
          {showCardSwipe &&
            <PlayerCardSwipe onSwipe={handleCardSwipe} swipeRegex={cardSwipeRegex}/>
          }
          {showManualAccountEntry &&
            <PlayerManualAccountEntry onSubmit={handleAccountEntrySubmit} />
          }
          {showEnterPin &&
            <PlayerCardPin onSubmit={handlePinSubmit}/>
          }
        </div>
      </div>
      <LoadingWithResultModal
        show={showVerifyingModal} 
        isComplete={!isPostingAccount} 
        title="Verifying Rewards Card"
        resultState={getInquiringResultState()}
        resultText={postingResultMessage}
        onTapToContinue={handleTapToContinue}
      />
    </>
  );
};

export default PlayerCardSwipePage;