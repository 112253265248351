import React, {useState} from 'react';
import './PlayerCardSwipe.scss';
import useCaptureCardSwipe from '../../hooks/CaptureCardSwipeHook';
import {useHistory} from 'react-router-dom';
import CardSwipeLottie from '../../common/lotties/CardSwipeLottie';
import LoadingWithResultModal from '../../common/modals/LoadingWithResultModal';
import {ResultStates} from '../../common/components/LoadingWithResultIndicator';
import {BottomButtonGroup} from '../../common/components';

const PlayerCardSwipe = ({ onSwipe, swipeRegex }) => {
  const [isSwipeSuccessful, setIsSwipeSuccessful] = useState(true);
  
  const history = useHistory();
  
  const handleSkipSwipeClicked = () => {
    history.push('/pay');
  };
  
  const handleSwipeFail = (swipeInput) => {
    console.error('Card swipe failed: ', swipeRegex, swipeInput);
    
    setIsSwipeSuccessful(false);
  };
  
  const getResultState = () => {
    return isSwipeSuccessful ? ResultStates.success : ResultStates.failure;
  };
  
  const { isSwiping } = useCaptureCardSwipe(onSwipe, handleSwipeFail, swipeRegex);
  
  return (
    <>
      <div className="player-swipe m-0 h-100">
        <div>
          <h1 className="player-swipe__title p-0 text-center">
            Swipe Your Rewards Card
          </h1>
        </div>
        <div className="p-0 player-swipe__swipe-animation">
          <CardSwipeLottie />
        </div>
        <div className="mb-5">
          <BottomButtonGroup buttons={[{
            name: 'Skip to Payment',
            primary: false,
            onClick: handleSkipSwipeClicked,
          }]} />
        </div>
      </div>
      <LoadingWithResultModal 
        title="Scanning Rewards Card" 
        show={isSwiping || !isSwipeSuccessful}
        isComplete={!isSwiping}
        resultState={getResultState()}
        resultText={isSwipeSuccessful ? '' : 'Oops! The scanned card was invalid. Please try again or ask a server for help.'}
        onTapToContinue={() => setIsSwipeSuccessful(true)}
      />
    </>
  );
};

export default PlayerCardSwipe;