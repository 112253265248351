import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const styles = {
  root: {
    margin: 'auto',
    background: 'none',
    display: 'block',
    shapeRendering: 'auto',
    width: 100,
    height: 100,
  },
};

const Spinner = ({
  complete,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={styles.root} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle 
      cx="50"
      cy="50"
      fill="none"
      stroke={useTheme().primaryColor || '#007bff'}
      strokeWidth="10"
      r="35"
      strokeDasharray={complete ? '0' : '164.93361431346415 56.97787143782138'}
      transform="rotate(12.0222 50 50)"
    >
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
    </circle>
  </svg>
);

Spinner.propTypes = {
  complete: PropTypes.bool,
};

export default Spinner;