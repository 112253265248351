import React from 'react';
import NumberPad from './NumberPad';
import './PinPad.scss';
import NumberPadButton from './NumberPadButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

const PinPad = ({ maxDisplayLength = 100, onSubmit }) => {
  const [pin, setPin] = React.useState('');
  
  const handleCharacterPressed = (char) => {
    setPin(prev => prev + char);
  };
  
  const handleClearPressed = () => {
    setPin('');
  };
  
  const handleSubmitClicked = () => {
    if (!pin) {
      return;
    }
    
    const pinToSubmit = pin;
    
    setPin('');
    
    onSubmit(pinToSubmit);
  };

  const getPinDisplayAsDots = () => {
    return pin
      .split('')
      .map(() => '•')
      .join('')
      .slice(0, maxDisplayLength);
  }
  
  return (
    <div className="pin-pad">
      <div className="pin-pad__display">
        {getPinDisplayAsDots()}
      </div>
      <div className="pin-pad__number-pad-container">
        <NumberPad useClear onCharacterPressed={handleCharacterPressed} onClearPressed={handleClearPressed} />
      </div>
      <div className="pin-pad__submit">
        <NumberPadButton variant="success" onClick={handleSubmitClicked}>
          <FontAwesomeIcon icon={faCheck} />
        </NumberPadButton>
      </div>
    </div>
  );
};

export default PinPad;