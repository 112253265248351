import React, {useContext} from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { KioskLogo } from '../images';
import { withMetadata } from '../providers';
import './MenuTopNav.scss';
import {MetadataContext} from '../providers/MetadataProvider';
import {getTerminalSettingAsBool} from '../TerminalHelpers';
import { HIDE_NAVBAR_INFO_BUTTON } from '../TerminalSettings';

const MenuTopNav = ({
    revCenter,
  }) => {
  
  const { terminal } = useContext(MetadataContext);

  const hideInfoButton = terminal
    ? getTerminalSettingAsBool(terminal, HIDE_NAVBAR_INFO_BUTTON)
    : false;

  return (
    <Navbar className="shadow-sm bg-white mtn" fixed="top">
      <Navbar.Brand>
        <img className="mtn__img" src={revCenter?.logoImageUrl || KioskLogo} alt={revCenter?.name || ''}/>
      </Navbar.Brand>

      {!hideInfoButton &&
        <Nav className="ml-auto">
          <Nav.Link target="_blank" href="https://www.boydgaming.com/mobile-ordering-faq">
            <i className="fa fas fa-info-circle"/>
          </Nav.Link>
        </Nav>
      }
    </Navbar>
  )
};

export default withMetadata(MenuTopNav);