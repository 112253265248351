import React from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const InfoBubble = ({ infoText }) => {
  return(
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      overlay={<Tooltip><p style={{ whiteSpace: 'pre-wrap'}}>{infoText}</p></Tooltip>}
    >
      <i className="ml-2 fa fa-info-circle" style={{ cursor: 'pointer' }} />
    </OverlayTrigger>                 
  );
};

export default InfoBubble;