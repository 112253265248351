import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Accordion, Card, Form, Modal } from 'react-bootstrap';
import './ProductSelectModal.scss';

import { withCheck, withSettings } from '../common/providers';
import { BottomButtonGroup } from '../common/components';
import Modifiers from './Modifiers';
import db from '../common/providers/MetadataProvider/metadataDb';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const Name = styled.h3`
  color: ${props => props.theme.primaryColor};
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CloseContainer = styled.div`
  color: #fff;
`;

const ProductSelectModal = ({
  orderByWeight,
  product,
  productGroup,
  forcedModifierGroups = {},
  exceptionModifierGroups,
  notesDisabled,
  show,
  onAdd,
  onHide,
}) => {	
  const [forcedMods, setForcedMods] = React.useState({});
  const [exceptionMods, setExceptionMods] = React.useState({});
  const [notes, setNotes] = React.useState('');
  const [upcharge, setUpcharge] = React.useState(0);
  const [image, setImage] = React.useState();
  const productForcedModGroups = product.forcedModifiersGroups || {};
  const notesRef = React.useRef();

  const hasNewLine = !!notes.match(/\n/);

  const handleAddProduct = () => {
    onAdd(forcedMods, exceptionMods, upcharge, notes.trim());
  };

  const handleForcedModToggle = (modGroupId, modId, mod) => {	  
    const curMods = { ...forcedMods };

    if (curMods[modGroupId] && curMods[modGroupId][modId]) {
      delete curMods[modGroupId][modId];
    } else {
      Object.assign(curMods, {
        [modGroupId]: {
          ...forcedMods[modGroupId],
          [modId]: mod,
        },
      });
    }

    setForcedMods(curMods);
    setUpcharge(upcharge + mod.price);
  };

  const handleExceptionModToggle = (modGroupId, modId, mod) => {
    let curMods = { ...exceptionMods };

    // If select, remove from list
    if (curMods[modGroupId] && curMods[modGroupId][modId]) {
      delete curMods[modGroupId][modId];
    } else {
      // Add to list
      curMods = {
        ...exceptionMods,
        [modGroupId]: {
          ...exceptionMods[modGroupId],
          [modId]: mod,
        },
      };
    }

    setExceptionMods(curMods);
    setUpcharge(upcharge + mod.price);
  };

  const hasRequiredMods = React.useMemo(() => {
    const requiredGroupIds = Object.keys(productForcedModGroups);

    // No required mods
    if (!requiredGroupIds.length) {
      return true;
    }

    const firstNonFulfilledGroup = requiredGroupIds.find(groupId => {

      // Hasn't been selected
      if (!forcedMods[groupId]) {
        return true;
      }
      
      // Min of the group not met
      const { min } = forcedModifierGroups[groupId];
      return Object.keys(forcedMods[groupId]).length < min;
    });

    // All required mods are met if there aren't any non-fulfilled groups
    return !firstNonFulfilledGroup;

  }, [forcedMods, forcedModifierGroups, productForcedModGroups]);

  React.useEffect(() => {
    const fetchImage = async () => {
      const prod = await db.products
        .where('id')
        .equals(product.id)
        .first();

      setImage(prod.image);
    };

    fetchImage();
    
  }, [product.id]);
  
  const getProductPriceTotal = () => {
    const productPrice = product.price;
    
    const allModifiers = Object.values(forcedMods).concat(Object.values(exceptionMods));
    
    const modifiersPriceTotal = allModifiers.reduce((modifierGroupAccumulator, modifierGroup) => {
      const modifierGroupTotal = Object
        .values(modifierGroup)
        .reduce((modifierAccumulator, modifier) => modifierAccumulator + modifier.price, 0);
      
      return modifierGroupAccumulator + modifierGroupTotal;
    }, 0);
    
    return productPrice + modifiersPriceTotal;
  };
  
  const getModalBodyClassName = () => {
    const defaultClassName = 'product-select-modal p-0';
    
    if (Object.keys(product.forcedModifiersGroups).length || Object.keys(productGroup.exceptionModifierGroups).length) {
      return defaultClassName;
    }
    
    // If there are no modifiers, set the height to auto to better fit the lack of content
    return `${defaultClassName} product-select-modal--auto-height`;
  };

  return (
    <Modal show={show} onHide={onHide} className="modal--full-screen-md" centered>
      <CloseContainer className="product-select-modal__close-button-container" onClick={onHide}>
        <button type="button" className="close" aria-label="Close">
          <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
        </button>
      </CloseContainer>

      <Modal.Body className={getModalBodyClassName()} style={{position: 'relative'}}>
        <div className="product-select-modal__content">
          {image && <Card.Img variant="top" src={image} alt={product.name}/>}
          <div className={`product-select-modal__title px-3 ${image ? 'py-3' : 'pb-4 pt-5'}`}>
            <div>
              <Name>
                <span className="font-weight-bolder mr-2">
                  {product.name}
                </span>
                <small>{Numeral(product.price).format('$0,0.00')}</small>
              </Name>

              {product.description &&
                <p>{product.description}</p>
              }
            </div>
          </div>

          <Modifiers
            columns={4}
            product={product}
            modGroupsOrderByWeight={orderByWeight}
            forcedModifiersGroups={forcedModifierGroups}
            productGroup={productGroup}
            exceptionModifiersGroups={exceptionModifierGroups}
            onForcedModToggle={handleForcedModToggle}
            onExceptionModToggle={handleExceptionModToggle}
          />

          {!notesDisabled &&
            <div className="product-select-modal__special-instructions">
              <Card className="border-0 m-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setTimeout(() => notesRef.current.focus(), 500)}
                    style={{cursor: 'pointer'}}
                    className="border-0"
                  >
                    <h5 className="m-0 p-0">Special Instructions</h5>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <div>
                      <Form.Control
                        as="textarea"
                        ref={notesRef}
                        name="notes"
                        placeholder="Additional charge might be applied for special requests"
                        isInvalid={hasNewLine}
                        maxLength={128}
                        style={{fontSize: '11pt'}}
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                      />
                      <div className="d-flex justify-content-between my-2">
                        <small className="text-danger">{hasNewLine && 'Notes cannot contain new line'}</small>
                        <small className="text-muted text-right">{notes.length} / 128</small>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </Card>
            </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer className="product-select-modal-footer">
        <div className="product-select-modal__bottom-actions">
          <button
            className="add-to-cart-button btn btn-primary"
            onClick={handleAddProduct}
            disabled={!hasRequiredMods || hasNewLine}>

            <div className="add-to-cart-button-container">
              {hasRequiredMods
                ? <span>Add to Order</span>
                : <span>Make Selections</span>
              }
              <span className="add-to-cart-button-amount">
                {Numeral(getProductPriceTotal()).format('$0,0.00')}
              </span>
            </div>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ProductSelectModal.propTypes = {
  orderByWeight: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  productGroup: PropTypes.object.isRequired,
  forcedModifierGroups: PropTypes.object,
  exceptionModifierGroups: PropTypes.object.isRequired,
  notesDisabled: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default compose(
  withCheck,
  withSettings,
)(ProductSelectModal);