import React from 'react';
import './NumberPad.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBackspace, faTimes} from '@fortawesome/free-solid-svg-icons';
import NumberPadButton from './NumberPadButton';

const NumberPad = ({ showDecimalButton, useClear, onCharacterPressed, onBackspacePressed, onClearPressed }) => {
  return (
    <div className="number-pad">
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('1')}>
          1
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('2')}>
          2
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('3')}>
          3
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('4')}>
          4
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('5')}>
          5
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('6')}>
          6
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('7')}>
          7
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('8')}>
          8
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('9')}>
          9
        </NumberPadButton>
      </div>
      <div style={{visibility: (showDecimalButton ? 'visible' : 'hidden')}}>
        <NumberPadButton onClick={() => onCharacterPressed('.')}>
          .
        </NumberPadButton>
      </div>
      <div>
        <NumberPadButton onClick={() => onCharacterPressed('0')}>
          0
        </NumberPadButton>
      </div>
      {useClear ? (
          <div>
            <NumberPadButton variant="gray" onClick={onClearPressed}>
              <FontAwesomeIcon icon={faTimes} />
            </NumberPadButton>
          </div>
        ) : (
          <div>
            <NumberPadButton variant="gray" onClick={onBackspacePressed}>
              <FontAwesomeIcon icon={faBackspace} />
            </NumberPadButton>
          </div>
        )
      }
    </div>
  );
};

export default NumberPad;