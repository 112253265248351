import React from "react";
import Numeral from "numeral";

const ModifierLineItem = ({ styleName, name, price}) => {

    return (
      <p className = {styleName}>
        {name} &nbsp;
        {!!price && 
          <span>({Numeral(price).format('$0,0.00')})</span>
        }
      </p>
    );
};

export { ModifierLineItem };