import styled from 'styled-components';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';

export const ThemedAlert = styled(Alert)`
  background: ${props => props.theme.primaryColor};
  color: var(--white);
`;

export const ThemedAnchor = styled.a`
  color: ${props => props.theme.primaryColor};
`;

export const ThemedButton = styled(Button)`
  background-color: ${props => props.theme.primaryColor};
  border-color: ${props => props.theme.primaryColor};
  transition: filter 300ms;

  &:hover {
    background-color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};
    filter: brightness(80%);
  }

  &:focus {
    background-color: ${props => props.theme.primaryColor} !important;
    border-color: ${props => props.theme.primaryColor} !important;
  }

  &:active {
    background-color: ${props => props.theme.primaryColor} !important;
    border-color: ${props => props.theme.primaryColor} !important;
    filter: brightness(60%);
  }

  &:disabled {
    background-color: ${props => props.theme.primaryColor} !important;
    border-color: ${props => props.theme.primaryColor} !important;
  }
`;

export const ThemedFlatButton = styled(Button)`
  color: ${props => props.theme.primaryColor};
`;

export const ThemedFormControl = styled(Form.Control)`
  &:focus,
  &:active {
    border-color: ${props => props.theme.primaryColor};
    box-shadow: none;
  }
`;

export const ThemedHeader = {
  h1: styled.h1`
    color: ${props => props.theme.primaryColor};
  `,
};

export const ThemedLinkButton = styled(Button)`
  color: ${props => props.theme.primaryColor};

  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;

export const ThemedSpinner = styled(Spinner)`
  color: ${props => props.theme.primaryColor};
`;