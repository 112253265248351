import React from 'react';
import styled from 'styled-components';
import { ThemedButton } from '../common/Themed';

const CircleButton = styled(ThemedButton)`
  border-radius: 50%;
  width: 28px !important;
  padding: 2px 0;

  @media only screen and (min-width: 576px) {
    width: 50px !important;
    padding: 12px 0;
  }
`;

const QuantityButton = ({
  children,
  ...rest         // Get all props passed in and spread on button
}) => {

  return (
    <CircleButton
      size="sm"
      {...rest}
    >
      {children}
    </CircleButton>
  );
};

export default QuantityButton;