import React, {useEffect} from 'react';
import {TapGestureLottieData} from '../../common/images';
import Lottie from '../components/Lottie';
import {replaceColor} from 'lottie-colorify';

// See lottie animation at: https://iconscout.com/lottie-animation/tap-gesture-4865807
const TapGestureLottie = ({ color }) => {
  const [colorModifiedLottie, setColorModifiedLottie] = React.useState(TapGestureLottieData);
  
  useEffect(() => {
    const modifiedLottie = color
      ? replaceColor('#000000', color, TapGestureLottieData)
      : TapGestureLottieData;

    setColorModifiedLottie(modifiedLottie);
  }, [color, replaceColor]);
  
  return (
    <Lottie animationData={colorModifiedLottie} preserveAspectRatio="xMidYMid meet" loop />
  );
};

export default TapGestureLottie;