import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { withSettings } from '../providers';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';

const DiscountListCard = ({
  check,
  settings,
  discounts,
  onApply,
}) => {
  const styles = {
    body: {
      padding: '1.8rem 2.8rem',
    },
    button: {
      backgroundColor: settings.primaryColor,
      width: 100,
    },
  };

  return (
    <Card className="border-0 shadow-sm">
      <Card.Body style={styles.body}>
        <Row className="pt-3 text-uppercase">
          <Col xs={6}>
            <strong>Discount</strong>
          </Col>
          <Col xs={3} className="text-right">
            <strong>Amount</strong>
          </Col>
          <Col xs={3}></Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        {!discounts.length && <em>No discounts available.</em>}
        {discounts.map((disc, index) =>
          <Row key={index} className="mb-3">
            <Col xs={6} className="d-flex align-items-center">
              <span style={styles.itemText}>{disc.name}</span>
            </Col>
            <Col
              xs={3}
              className="d-flex justify-content-end align-items-center"
            >
              <span className="mb-0">
                {Numeral(disc.amount).format('$0,0.00')}
              </span>
            </Col>
            <Col xs={3} className="text-right">
              <Button
                className="rounded-pill"
                variant="dark"
                size="sm"
                style={styles.button}
                disabled={!check.balance}
                onClick={() => !disc.applied && onApply(disc)}
              >
                {!disc.applied && 'Apply'}
                {disc.applied && <i className="fa fa-check" />}
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

DiscountListCard.propTypes = {
  check: PropTypes.object.isRequired,
  discounts: PropTypes.array.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default withSettings(DiscountListCard);