import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const SliderInput = styled.input`
  & {
    height: 34px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: ${props => props.theme.primaryColor};
    border-radius: 4px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    border: 1px solid ${props => props.theme.primaryColor};
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${props => props.theme.primaryColor};
  }

  &::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: ${props => props.theme.primaryColor};
    border-radius: 1px;
    border: 0px solid #010101;
  }

  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: ${props => props.theme.primaryColor};
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
  }

  &::-ms-fill-upper {
    background: ${props => props.theme.primaryColor};
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
  }

  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: ${props => props.theme.primaryColor};
  }

  &:focus::-ms-fill-upper {
    background: ${props => props.theme.primaryColor};
  }
`;

const Bubble = styled.span`
  position: absolute;
  bottom: -1.2rem;
  padding: .2rem .5rem .3rem .5rem;
  color: #fff;
  border-radius: 5px;
  textAlign: center;
  width: 55px;
  background: ${props => props.theme.primaryColor};
`;

const Slider = ({
  min = 0,
  max = 0,
  step = 0,
  value = 0,
  displayValue,
  onChange,
}) => {
  const newValue = Number((value - min) * 100 / (max - min))
  const newPosition = -18 - (newValue * 0.2);

  return (
    <Container>
      <SliderInput
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />

      {displayValue && (
        <Bubble
          style={{
            left: `calc(${newValue}% + (${newPosition}px))`
          }}
        >
          {displayValue}
        </Bubble>
      )}
    </Container>
  );
};

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  displayValue: PropTypes.string,
};

export default Slider;