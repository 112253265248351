import React from 'react';
import QuantityBadge from './QuantityBadge';
import GuestInfo from './GuestInfo';
import _ from 'lodash';
import { Card, Col, Row } from 'react-bootstrap';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { sortItems } from '../common/helpers';
import { ORDER_STATUS_CANCELLED } from '../common/Constants';
import { BottomButtonGroup, ConfirmCancelModal, Container, ImageItem, MenuFilter } from '../common/components';
import { withCheck, withMetadata } from '../common/providers';
import './ProductGroupList.scss';

// Product Groups from cache has rev types since it has associated products.
// Product Groups from menu has the menu position since it's specific to that menu.
//
// How to filter:
// 1. Merge all rev types from all prod groups which are associated with the prods
// 2. Sort by alpha asc
// --- At this point, the rev types can be shown to use as filter ---
// 3. When rev type is selected
//    a. Sort prod groups (by weight if applicable)
//    b. Only show the prod group if it contains the selected rev type

const ProductGroupList = ({
  addGuest,
  check,
  history,
  location,
  match,
  menus,
  productGroups,
  revenueTypes,
  terminal
}) => {
  const params = new URLSearchParams(location.search);
  const filter = params.get('filter') || 'all';
  
  const manualTableNumber = terminal._data.TerminalSettings.find(ts => ts.Name === 'pt-manual-table-number');
  const updateTableNo = manualTableNumber && manualTableNumber.Value.toLowerCase() === 'true' && check.orderType === 'Online';
  const hideTermsAndConditionsSetting = terminal._data.TerminalSettings.find(ts => ts.Name === 'pt-hide-terms-and-conditions');
  const hideTermsAndConditions = hideTermsAndConditionsSetting && hideTermsAndConditionsSetting.Value.toLowerCase() === 'true';
  const [showGuestInfo, setShowGuestInfo] = React.useState(!check?.guests.length);
  const [showCancel, setShowCancel] = React.useState(false);
  const { menuId } = match.params;
  const revTypeAll = {
    id: 'all',
    name: 'All',
    link: `/menus/${menuId}`,
  };

  const handleAddGuest = (guest) => {
    const first = guest.first.toLowerCase().trim();
    const last = guest.last.toLowerCase().trim();
    const tableNumber = guest.tableNumber !== '' ? guest.tableNumber : (check.orderType === 'Togo' ? last :null);
    
    const newGuest = {
      ...guest,
      name: `${first} ${last}`,
      id: `${first[0]}${last}`,
      tableNumber
    };

    addGuest(newGuest);
    setShowGuestInfo(false);
  };

  const handleCancel = () => history.push(`/thankyou?status=${ORDER_STATUS_CANCELLED}`);

  const handleCheckout = () => history.push('/checkout');

  const sortedRevenueTypes = React.useMemo(() => {
    const sorted = Object.keys(productGroups)
      .map(groupId => productGroups[groupId].revenueTypes || [])
      .reduce((merged, arr) => _.union(merged, arr), [])
      .map(revTypeId => ({
        ...revenueTypes[revTypeId],
        id: revTypeId,
        link: `/menus/${menuId}?filter=${revTypeId}`,
      }))
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

    return [revTypeAll].concat(sorted);
  }, [menuId, productGroups, revenueTypes, revTypeAll]);

  // Try to find the rev type user filter param from query string.
  // If rev type not found, default to all
  const selectedRevType = sortedRevenueTypes.find(rev => rev.id === filter) || revTypeAll;

  const sortedProductGroups = React.useMemo(() => {
    const menu = menus[menuId];
    if (!menu) {
      console.warn('Menu not found!');
      return [];
    }

    const mappedProductGroups = Object.keys(menu.productGroups)
      .map(id => ({
        ...productGroups[id],       // This has rev types
        ...menu.productGroups[id],  // This has menu position
        id,
      }));

    const sorted = sortItems(mappedProductGroups, menu.orderByWeight);

    if (selectedRevType.id === 'all') {
      return sorted;
    }

    return sorted.filter(group => group.revenueTypes.indexOf(selectedRevType.id) >= 0);

  }, [menus, menuId, productGroups, selectedRevType.id]);

  const bottomButtons = [
    {
      variant: 'dark',
      onClick: () => setShowCancel(true),
      content: 'Cancel Order',
    },
    {
      variant: 'dark',
      primary: true,
      disabled: !check.totalQuantity,
      onClick: () => handleCheckout(),
      content: (
        <>
          Checkout {!!check.totalQuantity && <QuantityBadge quantity={check.totalQuantity} />}
        </>
      ),
    }
  ];

  return (
    <Container className="pgl" style={{ paddingBottom: 100 }}>

      <div>
        <MenuFilter selectedId={selectedRevType.id} options={sortedRevenueTypes} />

        <Row>
          {sortedProductGroups.map(group =>
            <Col key={group.id} lg={4} className="pgl__pg">
              <Card
                className="pgl__card text-dark text-decoration-none"
                key={group.id}
                as={Link}
                to={`${match.url}/productgroups/${group.id}`}
              >
                <Card.Body>
                  <ImageItem item={group} />
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <BottomButtonGroup buttons={bottomButtons} />
      </div>

      <GuestInfo
        updateTableNo={updateTableNo}
        hideTermsAndConditions={hideTermsAndConditions}
        show={showGuestInfo}
        onConfirm={handleAddGuest}
        onCancel={() => setShowGuestInfo(false)}
      />

      <ConfirmCancelModal
        message="Are you sure you want to cancel?"
        show={showCancel}
        onCancel={() => setShowCancel(false)}
        onConfirm={handleCancel}
      />


    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
)(ProductGroupList);