import React, {useRef} from 'react';
import {default as ReactLottie} from 'react-lottie';

const Lottie = ({ animationData, loop = false, preserveAspectRatio }) => {
  const lottieOptions = {
    loop: loop,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: preserveAspectRatio || 'xMidYMid slice'
    }
  };

  return (
    <ReactLottie options={lottieOptions} isClickToPauseDisabled={true} ariaRole="" />
  );
};

export default Lottie;