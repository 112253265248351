import React from 'react';
import QuantityBadge from './QuantityBadge';
import Products from './Products';
import ProductSelectModal from './ProductSelectModal';
import { Button } from 'react-bootstrap';
import { compose } from 'recompose';
import { withCheck, withMetadata, withToast } from '../common/providers';
import { BottomButtonGroup, ConfirmCancelModal, Container, TopNavBar } from '../common/components';
import { ORDER_STATUS_CANCELLED } from '../common/Constants';

import './Menu.scss';

const Menu = ({
  addItem,
  check,
  history,
  match,
  menus,
  productGroups,
  products,
  exceptionModifierGroups,
  forcedModifierGroups,
  revCenter,
  toast,
}) => {
  const [selected, setSelected] = React.useState({ productId: null, productGroupId: null });
  const [showCancel, setShowCancel] = React.useState(false);
  const { menuId, productGroupId } = match.params;
  const menu = menus[menuId];

  const handleCancel = () => history.push(`/thankyou?status=${ORDER_STATUS_CANCELLED}`);

  const handleProductSelect = (productGroupId, productId) => {
    setSelected({ productId, productGroupId });
  };

  const handleAddProduct = (forcedMods, exceptionMods, upcharge, notes) => {
    const product = products[selected.productId];
    const { id, name, price, externalId, } = product;

    addItem({
      id,
      name,
      price: price + upcharge,
      productGroupId: selected.productGroupId,
      productId: selected.productId,
      forcedMods,
      exceptionMods,
      externalId,
      notes,
    });

    setSelected({ productId: null, productGroupId: null });
    toast({ success: `${name} added!` });
  };

  const handleCheckout = () => {
    gotoCheckout();
  }

  const gotoCheckout = () => history.push('/checkout');

  const bottomButtons = [
    {
      variant: 'dark',
      onClick: () => setShowCancel(true),
      content: 'Cancel Order',
    },
    {
      variant: 'dark',
      primary: true,
      disabled: !check.totalQuantity,
      onClick: () => handleCheckout(),
      content: (
        <>
          Checkout {!!check.totalQuantity && <QuantityBadge quantity={check.totalQuantity} />}
        </>
      ),
    }
  ];

  return (
    <Container className="menu">

      <div className="menu__top">
        <Button
          variant="link"
          className="menu__icon"
          onClick={() => history.goBack()}
        >
          <i className="fa fa-chevron-left 2x" />
        </Button>
        <TopNavBar title={productGroups[productGroupId].name} buttons={[]} />
      </div>

      <Products
        orderByWeight={productGroups[productGroupId].orderByWeight}
        productGroup={productGroups[productGroupId]}
        onSelect={handleProductSelect}
      />

      <BottomButtonGroup buttons={bottomButtons} />

      {!!selected.productId && !!selected.productGroupId &&
        <ProductSelectModal
          show={true}
          orderByWeight={menu.orderByWeight}
          product={products[selected.productId]}
          forcedModifierGroups={forcedModifierGroups}
          productGroup={productGroups[selected.productGroupId]}
          exceptionModifierGroups={exceptionModifierGroups}
          notesDisabled={revCenter.notesDisabled}
          onAdd={handleAddProduct}
          onHide={() => setSelected({ productId: null, productGroupId: null })}
          toast={toast}
        />
      }

      <ConfirmCancelModal
        message="Are you sure you want to cancel?"
        show={showCancel}
        onCancel={() => setShowCancel(false)}
        onConfirm={handleCancel}
      />
    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withToast,
)(Menu);