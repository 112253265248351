
export const getTerminalSetting = (terminal, terminalSettingName) => {
    if (!terminal)
        return null;
    
    const terminalSetting = terminal._data.TerminalSettings.find(setting => setting.Name === terminalSettingName);
    
    return terminalSetting
        ? terminalSetting.Value
        : null;
}

export const getTerminalSettingAsBool = (terminal, terminalSettingName) => {
    const terminalSetting = getTerminalSetting(terminal, terminalSettingName);

    return terminalSetting
      ? terminalSetting.toLowerCase() === 'true'
      : null;
}
