import React, {useContext} from 'react';
import { ThemedButton } from '../common/Themed';
import { withRouter } from 'react-router-dom';
import {withCheck} from '../common/providers';
import './OrderTypeSelection.css';
import CarryOutIcon from './images/ICON_Take-out_388x388-WHITE.png';
import DineInIcon from './images/ICON_Dine-in_388x388-WHITE.png';
import {compose} from "recompose";
import {ButtonGroup} from "react-bootstrap";
import {CheckContext} from "../common/providers/CheckProvider/CheckProvider";

const OrderTypeSelection = ({
    history,
}) => {

    const {updateCheck } = useContext(CheckContext);
    
    const DineInSelected = () => {
        updateCheck({orderType: 'Online'});
        history.push('/');
    }
    
    const TakeOutSelected = () => {
        updateCheck({orderType: 'Togo'});
        history.push('/')
    }
    
return(
    <div className={"order_type_page"}>
      <h3 className={"order_type_header"}>Choose your Order type to begin.</h3>
      <ButtonGroup className={"order_type_button_group"}>
        <ThemedButton className="order_type_button" onClick={DineInSelected}>
          <img className="order_type_selection_image" alt="DineInLogo" src={DineInIcon} />
          <text className="order_type_selection_text">DINE IN</text>
        </ThemedButton>
        <ThemedButton className="order_type_button" onClick={TakeOutSelected}>
          <img className="order_type_selection_image" alt="TakeoutLogo" src={CarryOutIcon}/>
          <text className="order_type_selection_text">TAKEOUT</text>
        </ThemedButton>
      </ButtonGroup>
    </div>
)};

export default compose(
    withCheck,
    withRouter,
)(OrderTypeSelection);