import React from 'react';
import ReactDOM from 'react-dom';
import { SettingsProvider, MetadataProvider, CheckProvider } from './common/providers';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import {ConsoleLogProvider} from './common/providers/ConsoleLogProvider';
import {TimeoutProvider} from './common/providers/TimeoutProvider';

const POLLING_INTERVAL = 1000 * 30;

ReactDOM.render(
  <SettingsProvider>
    <MetadataProvider pollingInterval={POLLING_INTERVAL}>
      <CheckProvider>
        <ConsoleLogProvider>
          <TimeoutProvider>
            <Router>
              <App />
            </Router>
          </TimeoutProvider>
        </ConsoleLogProvider>
      </CheckProvider>
    </MetadataProvider>
  </SettingsProvider>
,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
