import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { compose } from 'recompose';
import { ORDER_STATUS_CANCELLED, ORDER_STATUS_ERROR, ORDER_STATUS_TIMEOUT } from '../common/Constants';
import { PromoList } from '../common/components';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';
import EmailModal from './EmailModal';
import * as PosApi from '../common/PosApi';
import {TimeoutContext} from '../common/providers/TimeoutProvider';
import {getTerminalSetting} from '../common/TerminalHelpers';
import * as TerminalSettings from '../common/TerminalSettings';

const ORDER_STATUS_SUCCESS = 'success';

const Title = styled.h1`
  margin-bottom: .5rem !important;
  color: ${props => props.success ? 'var(--success)' : props.theme.primaryColor};
`;

const ListItem = styled(ListGroup.Item)`
  display: flex;
`;


const IconCol = styled.div`
  width: 32px;
`;

const InfoCol = styled.div`
  flex-grow: 1;
`;

const Caption = {
  [ORDER_STATUS_SUCCESS]: {
    title: 'Thank You For Ordering',
    description: '',
  },
  [ORDER_STATUS_CANCELLED]: {
    title: 'Thank you',
    description: 'Rescan QR code to place a new order.',
  },
  [ORDER_STATUS_ERROR]: {
    title: 'See Server',
    description: 'There was an issue sending your order.  Please see server.',
  },
  [ORDER_STATUS_TIMEOUT]: {
    title: 'Session Timeout',
    description: 'Your order session has timed out. You may rescan QR code to place a new order.',
  },
}

const Thankyou = ({
  check,
  location,
  revCenter,
  resetMetadata,
  resetSettings,
  toast,
  terminal,
}) => {
  const params = new URLSearchParams(location.search);
  const status = params.get('status') || 'success';

  const {
    setTimeoutMilliseconds,
    resetTimeoutDurationToDefault,
    resetTimeoutTimer,
  } = useContext(TimeoutContext);

  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get('orderNumber');

  const [showEmailReceiptModal, setShowEmailReceiptModal] = React.useState(false);
  const [isSendingText, setIsSendingText] = React.useState(false);
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  // If status is not supported, treat it like a cancelled (generic) message
  const caption = Caption[status] ? Caption[status] : Caption[ORDER_STATUS_CANCELLED];

  useEffect(() => {
    const timeoutTime = getTerminalSetting(terminal, TerminalSettings.FINALIZE_ORDER_TIMEOUT);
    
    if (!timeoutTime) {
      return;
    }
    
    setTimeoutMilliseconds(timeoutTime * 1000);
    resetTimeoutTimer();
    
    return () => {
      resetTimeoutDurationToDefault();
    };
  }, []);

  const redirect = async () => {
    try {
      await resetMetadata();
      resetSettings();
    } catch (err) {
      console.error(err);
    }
    
    const redirectUrl = getTerminalSetting(terminal, TerminalSettings.FINALIZE_ORDER_REDIRECT_URL);
    
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  const onEmailReceiptClicked = () => {
    setShowEmailReceiptModal(true);
  };

  const onTextReceiptClicked = async () => {
    try {
      setIsSendingText(true);

      const [guest] = check.guests;
      const phoneNumber = guest.phone;
      
      // Start both the API call and a 5-second timer
      // The 5 second timer is used to prevent spamming the receipt button
      const sendReceiptLambdaCall = PosApi.sendReceiptToMobile(check.id, 1, phoneNumber);
      const spamPreventionTimer = new Promise(resolve => setTimeout(resolve, 5000));

      // Wait for both to complete
      await Promise.all([sendReceiptLambdaCall, spamPreventionTimer]);

      toast({ success: "Receipt was sent!" });
      redirect();
    } catch (err) {
      console.log(err);

      toast({ error: "Failed to send the receipt! Please see a server." });
    } finally {
      setIsSendingText(false);
    }
  };

  const onEmailReceiptSubmitted = async (email) => {
    try {
      setShowEmailReceiptModal(false);

      setIsSendingEmail(true);

      await PosApi.sendReceiptToEmail(check.id, email);

      toast({ success: "Receipt was sent!" });
      redirect();
    } catch (err) {
      console.log(err);

      toast({ error: "Failed to send the receipt! Please see a server." });
    } finally {
      setIsSendingEmail(false);
    }
  };

  React.useEffect(() => {
    if (status === ORDER_STATUS_CANCELLED) {
      redirect();
    }
  }, []);

  return (
    <div className="welcome p-4 d-flex flex-column justify-content-between">
      <div className="text-center">
        <Title success={status === 'success'}>
          {caption.title}
        </Title>

        {orderNumber && <h4 className="text-success">Order #{orderNumber}</h4>}

        {status === 'success' &&
          <i className="fa fa-check-circle fa-2x ml-2 mt-2 mb-3 text-success" />
        }

        <p className="mb-0">{caption.description}</p>
      </div>

      {status === ORDER_STATUS_SUCCESS &&
        <div>
          <div>
            <div className="col-12 d-flex justify-content-center my-3">
              <button className="btn btn-primary col-sm-auto col-xs-12" onClick={onEmailReceiptClicked} disabled={isSendingEmail}>
                {isSendingEmail ? (
                  <>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Emailing Receipt...
                  </>
                ) : (
                  <>
                    <i className="fa fa-envelope mr-2"></i> Email Receipt
                  </>
                )}
              </button>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button className="btn btn-primary col-sm-auto col-xs-12" onClick={onTextReceiptClicked} disabled={isSendingText}>
                {isSendingText ? (
                  <>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Texting Receipt...
                  </>
                ) : (
                  <>
                    <i className="fa fa-mobile-alt mr-2"></i> Text Receipt
                  </>
                )}
              </button>
            </div>
            <div className="col-12 d-flex justify-content-center my-3">
              <button className="btn btn-primary col-sm-auto col-xs-12" onClick={redirect} >
                No Receipt
              </button>
            </div>
          </div>
          <div className="text-center">
            <small className="text-muted">Choose an option to receive your receipt</small>
          </div>
        </div>
      }

      {status === ORDER_STATUS_ERROR &&
        <div>
          <ListGroup className="my-3">
            <ListItem>
              <IconCol>
                <i className="fa fas faw fa-building" />
              </IconCol>
              <InfoCol>
                {revCenter.name}
              </InfoCol>
            </ListItem>

            <ListItem>
              <IconCol>
                <i className="fa fas faw fa-map-marker" />
              </IconCol>
              <InfoCol>
                <div>{revCenter.address}</div>
                <div>{revCenter.city}, {revCenter.state} {revCenter.zip}</div>
              </InfoCol>
            </ListItem>

            <ListItem>
              <IconCol>
                <i className="fa fas fa-phone" />
              </IconCol>
              <InfoCol>
                <a href={`tel:${revCenter.phone}`}>
                  {revCenter.phone}
                </a>
              </InfoCol>
            </ListItem>
          </ListGroup>
          <div className="text-center">
            <small className="text-muted d-block pt-2">You may close this window</small>
          </div>
        </div>
      }

      {!!revCenter.promos.length && <PromoList promos={revCenter.promos} />}

      {/* Modals for receipt sending options. */}
      <EmailModal 
        show={showEmailReceiptModal} 
        onSubmit={(email) => onEmailReceiptSubmitted(email)}
        onCancel={() => setShowEmailReceiptModal(false)} 
      />
    </div>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast
)(Thankyou);