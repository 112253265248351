import Moment from 'moment';

/**
 *      POS     JS
 *      ---     ---
 * Mon: 1       1
 * Tue: 2       2
 * Wed: 4       3
 * Thu: 8       4
 * Fri: 16      5
 * Sat: 32      6
 * Sun: 64      0 
 */
const DAY_OF_WEEK = [
  64, // Sun
  1,  // Mon
  2,  // Tue
  4,  // Wed
  8,  // Thu
  16, // Fri
  32, // Sat
];

const isCurrentMenu = (menu) => {
  const startDate = Moment().format('YYYY-MM-DD');
  const start = Moment(`${startDate} ${menu.start}`);

  const isNextDay = menu.end <= menu.start;
  const endDate = Moment().add(isNextDay ? 1 : 0, 'd').format('YYYY-MM-DD');
  const end = Moment(`${endDate} ${menu.end}`);

  // Check if menu should be shown today
  const dayNum = new Date().getDay();
  const today = DAY_OF_WEEK[dayNum];
  const isToday = (menu.daysToRunFlags & today) === today;
  const isInTimeRange = Moment().isBetween(start, end);

  return isToday && isInTimeRange;
};

const getCurrentMenuId = (menus) => {
  const sortedMenus = Object
    .keys(menus)
    .map(key => Object.assign({}, menus[key], { id: key }))
    .sort((cur, next) => {
      const curStart = Moment(`2020-01-01 ${cur.start}`).toISOString();
      const nextStart = Moment(`2020-01-01 ${next.start}`).toISOString();

      return curStart < nextStart ? -1 :
             curStart > nextStart ?  1 : 0;
    });

  const curMenu = sortedMenus.find(menu => isCurrentMenu(menu));
  return curMenu ? curMenu.id : null;
};

export default getCurrentMenuId;