import { LocalCache } from '.';
import { NOTES_DEFAULT_ID } from './Constants';
import _ from 'lodash';
import * as Cache from './LocalCache';
import * as MathHelper from './MathHelper';

export const ActionType = {
  GET_ORDER: 'getOrder',
  FINALIZE_ORDER: 'finalizeOrder',
  POST_INQUIRY: 'postInquiry',
  POST_ORDER: 'postOrder',
  POST_PAYMENT: 'postPayment',
  POST_TRANSACTION: 'postTransaction',
  POST_CUSTOMER_ACCOUNT: 'postCustomerAccount',
  VOID_ORDER: 'voidOrder',
  SEND_RECEIPT_TO_EMAIL: 'sendReceiptToEmail',
  SEND_RECEIPT_TO_MOBILE: 'sendReceiptToMobile',
};

export async function postLambda(action, payload) {
  const { token } = Cache.getSettings();
  const resourceUrl = process.env.REACT_APP_API_BASE_URL;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action,
      payload,
    }),
  };

  const result = await fetch(resourceUrl, options);
  if (!result.ok) {
    throw new Error(result);
  }

  const response = await result.json();
  return response;
}

/**
 * Get an existing order.
 * @param {string} orderId The order identifier.
 * @param {Object} settings App settings.
 * @returns {Promise<Object>} Order.
 */
export async function getOrder(orderId) {
  const payload = { orderId };
  const apiOrder = await postLambda(ActionType.GET_ORDER, payload);
  return mapOrder(apiOrder);
}

export async function postOrderPayload(payload) {
  const apiOrder = await postLambda(ActionType.POST_ORDER, payload);
  return mapOrder(apiOrder);
}

/**
 * @typedef InquiryRequest
 * @property {string} accountId Account id or player id.
 * @property {string} tenderTypeId Tender type identifier.
 * @property {boolean} isLookup Inquire as a lookup (currently not supported).
 * @property {Object} check Check object.
 */

/**
 * Post an inquiry for an account, comps, points, etc.
 * @param {InquiryRequest} inquiryRequest Inquiry request for POS API.
 * @param {Object} settings App settings.
 */
export async function inquiry(inquiryRequest, settings) {
  const { url, terminalId } = settings;
  const { accountId, tenderTypeId, isLookup, check } = inquiryRequest;
  const resourceUrl = getResourceUrl(url, 'Accounts');

  const inquiry = {
    OrderId: check.id,
    TenderTypeId: tenderTypeId,
    TerminalId: terminalId,
    AccountId: accountId,
    IsLookup: isLookup,
  };

  const apiAccounts = await post(resourceUrl, inquiry, settings);
  return apiAccounts.map((acct) => Object.assign({}, mapAccount(acct), { tenderTypeId }));
}

/**
 * Print an itemized chit.
 * @param {string} orderId Order id or check id.
 * @param {Object} settings App settings.
 */
export async function printItemizedReceipt(orderId, settings) {
  // TODO: Implement printing on API
  console.warn('Printing not implemented.');

  // Emulate printing
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, 1000)
  );
}

/**
 * Post kiosk check to POS API.
 * @param {Object} check Check object.
 * @param {Object} settings App settings.
 * @returns {Promise<Object>} New check.
 */
export async function postCheck(check, settings) {
  const products = LocalCache.getProducts();
  const terminal = LocalCache.getTerminal();
  const onlineOrderType = 'Online';

  const keys = Object.keys(check.items);

  const payload = {
    order: {
      _data: {
        UserId: terminal._data.DefaultUserId,
        OrderType: onlineOrderType,
      },
    },
    sales: [],
    guest: check.guests[0],
  };

  // POST each sale to the POS order
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const item = check.items[key];
    const product = products[item.id];

    const prod = {
      _data: {
        //OrderId: order.id,  // Lambda should add this in after posting order
        ProductId: item.id,
        ProductsMainCatId: item.productGroupId,
        Name: item.name,
        CountOrdered: item.quantity.toString(), // OData likes decimal as string
        MenuPrice: product.price.toString(), // OData likes decimal as string
        OwningSeatNumber: 1,
        SeatSequenceNumber: i + 1,
        UserId: terminal._data.DefaultUserId,
      },
      _mods: [],
    };

    // Send modifiers: 0 = forced, 1 = exception
    for (let j = 0; j < 2; j++) {
      const isForcedMods = j === 0;

      const mods = createMods({
        isForcedMods,
        userId: terminal._data.DefaultUserId,
        modifiers: isForcedMods ? item.forcedMods : item.exceptionMods,
        countOrdered: item.quantity,
      });

      prod._mods = prod._mods.concat(mods);
    }

    // Notes are sent as exception modifiers w/ a lot of empty GUIDS.
    // Make look up on the fly and also create the notes to look like
    // a modifier.
    if (item.notes) {
      const noteMods = createMods({
        isForcedMods: false,
        userId: terminal._data.DefaultUserId,
        modConfigData: {
          [NOTES_DEFAULT_ID]: {
            _data: {
              RevenueTypeId: NOTES_DEFAULT_ID,
            },
          },
        },
        modifiers: {
          [NOTES_DEFAULT_ID]: {
            [NOTES_DEFAULT_ID]: {
              id: NOTES_DEFAULT_ID, // Set id as product id
              // name: item.name,         // Set by API
              notes: item.notes,
              price: 0,
            },
          },
        },
      });

      prod._mods = prod._mods.concat(noteMods);
    }

    payload.sales.push(prod);
  }

  // Refresh order
  const order = await postOrderPayload(payload);
  return {
    ...check,
    total: order.total,
    balance: order.balance,
    tax: order.tax,
    subtotal: order.subtotal,
    number: order.number,
    id: order.id,
  };
}

/**
 *
 * @param {Object} modifiers Forced/Exception modifiers from check item.
 */
function createMods({ userId, modifiers, isForcedMods, countOrdered }) {
  const groupIds = Object.keys(modifiers);
  const sales = [];

  for (let groupId of groupIds) {
    const mods = modifiers[groupId];
    const modIds = Object.keys(mods);

    for (let modId of modIds) {
      const mod = mods[modId];

      const sale = {
        //OrderId: orderId,                   // Lambda will add this in
        //ParentSaleWhenModSaleId: productId, // Must be set after product is posted as sale on lambda
        ProductId: mod.id,
        ProductsMainCatId: groupId,
        Name: mod.name,
        Notes: mod.notes || null,
        IsSeeServer: !!mod.notes,
        IsForcedModifier: isForcedMods,
        IsExceptionModifier: !isForcedMods,
        MenuPrice: mod.price.toString(),
        OwningSeatNumber: 1,
        UserId: userId,
        CountOrdered: countOrdered?.toString() ?? '1',
      };

      sales.push(sale);
    }
  }

  return sales;
}

export async function postInquiry(inquiryRequest) {
  const accounts = await postLambda(ActionType.POST_INQUIRY, inquiryRequest);
  return accounts;
}

export async function postCustomerAccount(customerAccountRequest) {
  return await postLambda(ActionType.POST_CUSTOMER_ACCOUNT, customerAccountRequest);
}

export async function postCreditCardPayment(check, tenderTypeId, result, amount, tip, cardInfo) {
  const { ccAuthReply: auth, requestID } = result;

  const roundedAuthorizedAmount = MathHelper.round(amount, 2);
  const roundedTipAmount = MathHelper.round(tip, 2);

  const payload = {
    orderId: check.id,
    postedPayment: {
      _data: {
        Amount: roundedAuthorizedAmount.toString(),
        AccountNumber: cardInfo.cardNumber,
        Issuer: cardInfo.issuer,
        Tax: check.tax.toString(),
        Tip: roundedTipAmount.toString(),
        InvoiceNumber: check.number,
        TenderTypeId: tenderTypeId,
        Name: 'Credit Card',
        CustomerReference: '',
        StatusCode: auth.authorizationCode,
        ReferenceId: requestID,
        ApiTransactionId: auth.processorTransactionID,
      },
    },
  };

  await postLambda(ActionType.POST_PAYMENT, payload);
}

/**
 * @typedef Payment
 * @property {string} name The name of the payment.
 * @property {number} amount The amount to authorize.
 * @property {number} tip The tip amount, additional to amount.
 * @property {string} tenderTypeId The tender type identifier.
 * @property {Object} check The check object.
 * @property {Object} extendedData The exact data sent back from inquiry. Not required for "Other" tender types.
 */

/**
 * Post a payment.
 * @param {Payment} payment The payment request.
 * @returns {Promise<Object>} Payment object.
 */
export async function postTransaction(payment) {
  const payload = {
    transaction: {
      _data: {
        OrderId: payment.check.id,
        Authorized: payment.amount.toString(),
        Gratuity: (payment.tip || 0).toString(),
        TenderType_Id: payment.tenderTypeId,
        ExtendedData: JSON.stringify(payment.extendedData),
        Name: payment.name,
      },
    },
  };

  const apiTrans = await postLambda(ActionType.POST_TRANSACTION, payload);
  return mapTransaction(apiTrans);
}

export async function finalizeOrder(check) {
  const revCenter = LocalCache.getRevCenter();
  const [guest] = check.guests;
  const payload = {
    guest,
    order: {
      ...check,
      profitCenterId: revCenter.id,
    },
    autoClose: revCenter.autoClose,
  };

  await postLambda(ActionType.FINALIZE_ORDER, payload);
}

/**
 * @typedef UpdateOrder
 * @property {Object} check Check object.
 * @property {boolean} isActive Is the order active on kiosk?
 * @property {boolean} isClosed Is this order closed?
 */

/**
 *
 * @param {UpdateOrder} order UpdateOrder object.
 * @param {Object} settings App settings.
 * @return {Promise<void>}
 */
export async function updateOrder(order, settings) {
  const { url, terminalId } = settings;
  const resourceUrl = getResourceUrl(url, 'Orders', order.check.id);

  const req = {
    ActiveOnTerminalId: order.isActive ? terminalId : null,
    IsClosed: order.isClosed,
  };

  await doFetch(resourceUrl, settings, {
    method: 'PUT',
    body: JSON.stringify(req),
  });
}

export async function voidOrder(orderId) {
  const payload = { orderId };
  await postLambda(ActionType.VOID_ORDER, payload);
}

export async function sendReceiptToEmail(orderId, email) {
  const payload = { orderId, email };
  await postLambda(ActionType.SEND_RECEIPT_TO_EMAIL, payload);
}

export async function sendReceiptToMobile(orderId, checkNumber, phoneNumber) {
  const revenueCenterId = LocalCache.getRevCenter().id;

  const payload = { revenueCenterId, orderId, checkNumber, phoneNumber };

  await postLambda(ActionType.SEND_RECEIPT_TO_MOBILE, payload);
}

/**
 * Helpers
 */

/**
 * Get full resource url.
 * @param {string} url Root api url.
 * @param {string} resource Entity name, typically plural.
 * @param {string} id The identifier of the entity, if getting a single entity.
 * @param {string} options Query string without '?'.
 */
function getResourceUrl(url, resource, id, options) {
  let resourceUrl = `${url}/odata/${resource}`;

  if (!id) {
    return resourceUrl;
  }

  const allOptions = options ? `?${options}` : '';

  return `${resourceUrl}(guid'${id}')${allOptions}`;
}

/**
 * Handle initial fetch response.
 * @param {Response} httpResponse Fetch response.
 */
async function handleResponse(httpResponse) {
  let response = {};

  if (httpResponse.status !== 204) {
    response = await httpResponse.json();
  }

  if (!httpResponse.ok) {
    throw new Error(response.message || 'An error occurred with the server.');
  }

  return response;
}

function handleError(err) {
  console.error(err);

  let message = err.message;

  if (!message || message.match(/failed to fetch.*/i)) {
    message = 'Server is offline.';
  }

  throw new Error(message);
}

function mapAccount(account) {
  return {
    _data: account,
    id: account.accountId,
    name: account.displayName,
    amount: account.balance,
  };
}

function mapOrder(order) {
  const products = Cache.getProducts();
  
  return {
    id: order.Id,
    number: order.HumanReadableOrderId,
    subtotal: +order.SubTotal,
    tax: +order.ExclusiveTaxTotal + order.ServiceChargeClones.reduce((acc, surcharge) => acc + +surcharge.CorrectedTaxAmount, 0),
    balance: +order.TotalBalance,
    taxExemptBalance: +order.TotalTaxExemptBalance,
    total: +order.OrderTotal,
    isClosed: order.IsClosed,
    items: (order.Sales || []).reduce(
      (catalog, sale) => ({
        ...catalog,
        [sale.Id]: {
          id: sale.Id,
          name: sale.Name,
          productId: sale.ProductId,
          price: +sale.MenuPrice,
          quantity: +sale.CountOrdered,
          externalId: products[sale.ProductId]?.externalId,
          parentSaleWhenModSaleId: sale.ParentSaleWhenModSaleId,
          isSeeServer: sale.IsSeeServer,
          notes: sale.Notes
        },
      }),
      {}
    ),
    serviceCharges: (order.ServiceChargeClones || []).reduce(
      (catalog, surcharge) => ({
        ...catalog,
        [surcharge.SurchargeConfigurationId]: {
          name: surcharge.Name,
          amount: +surcharge.CorrectedAmount + (catalog[surcharge.SurchargeConfigurationId]?.amount || 0),
        },
      }),
      {}
    ),
    autoGratuities: _.flatten((order.Sales || []).map((s) => s.AutoGratuityClones || [])).reduce(
      (catalog, grat) => ({
        ...catalog,
        [grat.AutoGratuityId]: {
          name: grat.Name,
          amount: +grat.CorrectedAmount + (catalog[grat.AutoGratuityId]?.amount || 0),
        },
      }),
      {}
    ),
    payments: (order.Transactions || []).map((trans) => ({
      id: trans.Id,
      name: trans.Name,
      amount: +trans.Authorized,
      tip: +trans.Gratuity,
      tenderTypeId: trans.TenderType_Id,
    })),
  };
}

function mapTransaction(trans) {
  return {
    _data: trans,
    id: trans.Id,
    name: trans.Name,
    amount: trans.Authorized,
    tip: trans.Gratuity,
    tenderTypeId: trans.TenderType_Id,
  };
}

/**
 * Perform POST request.
 * @param {string} resourceUrl Full resource url.
 * @param {Object} body Object to post.
 * @param {Object} settings App settings.
 */
function post(resourceUrl, body, settings) {
  return doFetch(resourceUrl, settings, { method: 'POST', body: JSON.stringify(body) });
}

/**
 *
 * @param {Object} options Options to merge with default options for fetch API.
 */
function doFetch(resourceUrl, settings, options) {
  const { token } = settings;
  const allOptions = Object.assign(
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
    options
  );

  return fetch(resourceUrl, allOptions).then(handleResponse).catch(handleError);
}
