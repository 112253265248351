import {useContext, useEffect, useState} from 'react';
import {ToastContext} from '../common/providers/ToastProvider';

const useCaptureCardSwipe = (onSwipeComplete, onSwipeFail, matchRegex) => {
  const [isSwiping, setIsSwiping] = useState(false);
  
  let currentSwipeInput = '';
  let cardSwipeTimerId;
  
  const { toast } = useContext(ToastContext);
  
  // Listens for keydown events to construct a swipe input
  useEffect(() => {
    const handleKeyDown = (event) => {
      resetSwipeInputSubmitTimer();
      
      if (!currentSwipeInput)
        setIsSwiping(true);
      
      currentSwipeInput += event.key;
    };

    // Add event listener for keydown events
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      
      if (cardSwipeTimerId)
        clearTimeout(cardSwipeTimerId);
    };
  }, []);

  // This interval will submit the swipe input after a delay of no input
  const resetSwipeInputSubmitTimer = () => {
    // Clear any existing interval
    if (cardSwipeTimerId)
      clearTimeout(cardSwipeTimerId);

    cardSwipeTimerId = setTimeout(() => {
      const resetSwipe = () => {
        currentSwipeInput = '';
        setIsSwiping(false); 
      };
      
      if (!matchRegex) {
        resetSwipe();
        
        onSwipeComplete(currentSwipeInput);
        
        return;
      }
      
      const isValidSwipe = validateCardSwipeIsMatch(currentSwipeInput, matchRegex);
      
      if (!isValidSwipe) {
        onSwipeFail(currentSwipeInput);
        
        resetSwipe();
        
        return;
      }
      
      const formattedSwipeInput = formatCardSwipe(currentSwipeInput, matchRegex);
      
      resetSwipe();
      
      onSwipeComplete(formattedSwipeInput);
    }, 1000);
  };
  
  const validateCardSwipeIsMatch = (swipeInput, swipeRegexString) => {
    const swipeRegex = new RegExp(swipeRegexString);
    
    return swipeInput.match(swipeRegex);
  }

  const formatCardSwipe = (swipeInput, swipeRegexString) => {
    const swipeRegex = new RegExp(swipeRegexString);

    const matches = swipeInput.match(swipeRegex);
    
    if (matches.length === 1)
      return matches[0];
    
    // Concatenate matches.  The first group is always the full match.
    // If only one group matched, then return value of group. If more than 1 group
    // matched, this means that RegEx meant to capture multiple groups which need
    // to be concatenated starting from Group index 1.
    
    const formattedSwipe = matches
      .slice(1)
      .join('');

    return formattedSwipe;
  };

  return {
    isSwiping,
  };
};

export default useCaptureCardSwipe;