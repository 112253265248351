import React from 'react';
import { compose } from 'recompose';
import { Col, Row } from 'react-bootstrap';

import { PosApi } from '../common';
import { ORDER_STATUS_CANCELLED } from '../common/Constants';
import { withCheck, withSettings } from '../common/providers';
import {
  BottomButtonGroup,
  Container,  
  ItemizedCard,
  Loading,
  PaymentListCard,
} from '../common/components';

const OrderSummary = ({
  check,
  removeItem,
  history,
  location,
  resetCheck,
  settings,
}) => {	
debugger;
  const [showPrinting, setShowPrinting] = React.useState(true);
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const { primaryColor } = settings;  
  
  const styles = {
    title: {
      fontWeight: 'bolder',
    },
    icon: {
      color: primaryColor,
    },
  };

  React.useEffect(() => {
    const printCheck = async () => {
      await PosApi.printItemizedReceipt(check.id, settings);
      setShowPrinting(false);					
    };	

    printCheck();		
  }, [check.id, settings]);
  
  function handleFinished() {
    history.push('/');
  }

  const bottomButtons = [
    {
      name: 'Finished',
      primary: true,
      onClick: handleFinished,
    }
  ];

  return (
    <Container>
      <Loading text="Printing receipt" show={showPrinting} icon="print" />

      <div className="d-flex justify-content-left align-items-center mb-4">
        <h2 className="mr-2 font-weight-bolder">
          Order {check.number && ` #${check.number}`}
        </h2>

        {status !== ORDER_STATUS_CANCELLED && (
          <span className="fa-stack fa-2x" style={styles.icon}>
            <i className="fa fa-circle fa-stack-2x" />
            <i className="fa fa-check fa-stack-1x fa-inverse" />
          </span>
        )}

        {status === ORDER_STATUS_CANCELLED && (
          <>
            <div style={styles.icon}>
              <span className="fa-stack fa-2x">
                <i className="fa fa-circle fa-stack-2x" />
                <i className="fa fa-exclamation-triangle fa-stack-1x fa-inverse" />
              </span>
            </div>
            <h4 className="ml-2 d-inline-block text-uppercase">See cashier</h4>
          </>
        )}
      </div>

      <Row>
        <Col xs={8}>
          <ItemizedCard items={check.items} />
        </Col>
        <Col xs={4}>
          <PaymentListCard check={check} />
        </Col>
      </Row>

      <BottomButtonGroup buttons={bottomButtons} />
    </Container>
  );
};

export default compose(
  withCheck,
  withSettings,
)(OrderSummary);