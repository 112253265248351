import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { compose } from 'recompose';
import { Card, Col, Row } from 'react-bootstrap';

import { PAYMENT_TYPE_CC, PAYMENT_TYPE_DISCOUNT, PAYMENT_TYPE_PLAYER_REWARD, PAYMENT_TYPE_PMS } from '../Constants';
import { withMetadata, withSettings } from '../providers';
import './PaymentListCard.scss';

const PaymentListCard = ({
  settings,
  tenderTypes,
  check
}) => {
  const { primaryColor } = settings;

  const styles = {
    body: {
      padding: '1.8rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    tip: {
      fontSize: '.9rem',
      marginLeft: '2.2rem',
    },
    iconSuccess: {
      color: primaryColor,
    },
    balance: {
      fontSize: '1.3rem',
      fontWeight: 'bolder',
    },
  };
  
  return (
    <Card className="paylist">
      <Card.Body>

        {!!check.payments.length && (
          <div className="paylist__payments">
            {check.payments.map((pay, index) =>
              <Row key={index} className="paylist__pay">
                <Col xs={6}>
                  <div>
                    {tenderTypes[pay.tenderTypeId]?.paymentType === PAYMENT_TYPE_PLAYER_REWARD && <i className="fa fa-tag" />}
                    {tenderTypes[pay.tenderTypeId]?.paymentType === PAYMENT_TYPE_DISCOUNT && <i className="fa fa-tag" />}
                    {tenderTypes[pay.tenderTypeId]?.paymentType === PAYMENT_TYPE_CC && <i className="fa fa-credit-card" />}
                    {tenderTypes[pay.tenderTypeId]?.paymentType === PAYMENT_TYPE_PMS && <i className="fa fa-building" />}
                    <span className="paylist__name">
                      {pay.name}
                    </span>
                  </div>

                  {!!pay.tip && (
                    <div className="paylist__tip" style={styles.tip}>Tip</div>
                  )}
                </Col>
                <Col xs={6} className="text-right">
                  <div>
                    {Numeral(pay.amount).format('$0,0.00')}
                  </div>

                  {!!pay.tip && (
                    <div className="paylist__tip" style={styles.tip}>{Numeral(pay.tip).format('$0,0.00')}</div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        )}

        <div className="text-muted">
          <Row className="paylist__subtotal">
            <Col>Subtotal</Col>
            <Col className="text-right">{Numeral(check.subtotal).format('$0,0.00')}</Col>
          </Row>
          <Row className="paylist__subtotal">
            <Col>Tax</Col>
            <Col className="text-right">
              {Numeral(check.tax).format('$0,0.00')}
            </Col>
          </Row>

          {check.autoGratuities && Object.keys(check.autoGratuities).map(key =>
            <Row className="paylist__subtotal" key={key}>
              <Col>{check.autoGratuities[key].name}</Col>
              <Col className="text-right">
                {Numeral(check.autoGratuities[key].amount).format('$0,0.00')}
              </Col>
            </Row>
          )}

          {check.serviceCharges && Object.keys(check.serviceCharges).map(key =>
            <Row className="paylist__subtotal" key={key}>
              <Col>{check.serviceCharges[key].name}</Col>
              <Col className="text-right">
                {Numeral(check.serviceCharges[key].amount).format('$0,0.00')}
              </Col>
            </Row>
          )}

          <Row>
            <Col />
            <Col><hr className="m-0" /></Col>
          </Row>
        </div>

        <div>
          <Row className="paylist__total">
            <Col xs={6}>Total</Col>
            <Col xs={6} className="text-right">{Numeral(check.total).format('$0,0.00')}</Col>
          </Row>
          <Row className="paylist__total paylist__total--bal">
            <Col xs={6}>
              Balance
              {!check.balance && <i className="fa fa-check-circle ml-2" style={styles.iconSuccess} />}
            </Col>
            <Col xs={6} className="text-right">{Numeral(check.balance).format('$0,0.00')}</Col>
          </Row>
        </div>

      </Card.Body>
    </Card>
  );
};

PaymentListCard.propTypes = {
  check: PropTypes.object.isRequired,
};

export default compose(
  withMetadata,
  withSettings
)(PaymentListCard);