import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { ThemedAnchor, ThemedButton, ThemedFormControl, ThemedLinkButton } from '../common/Themed';
import { useGoogleAnalytics } from '../common/providers';

const GuestInfoSchema = Yup.object().shape({
  first: Yup.string().required('First name is required'),
  last: Yup.string().required('Last name is required'),
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(
        /^[+]*([0-9][ ()+]*){10}$/,
        'Invalid mobile number, example: 7025551212'
    ),
  validateTableNo: Yup.boolean(),
  tableNumber: Yup.string()
    .when('validateTableNo', {
      is: true,
      then: Yup.string().required("Table number is required"),
      otherwise: Yup.string(),
  }),
  validateTerms: Yup.boolean(),
  terms: Yup.boolean()
    .when('validateTerms', {
      is: true,
      then: Yup.boolean().oneOf([true], 'You must agree to this'),
      otherwise: Yup.boolean(),
  }),
});

const GuestInfo = ({
  show = true,
  onConfirm,
  onCancel,
  updateTableNo,
  hideTermsAndConditions 
}) => {

  const { pushDataLayer } = useGoogleAnalytics();
  const firstRef = React.useRef();
  const validateTableNo = updateTableNo;
  const validateTerms = !hideTermsAndConditions;

  React.useEffect(() => {
    if (show) {
      pushDataLayer({ title: 'Guest Info' });
    }
  }, [pushDataLayer, show]);

  React.useEffect(() => {
    if (show) {
      firstRef.current.focus();
    }
  }, [show]);

  return (
    <Modal show={show}>
      <Modal.Body className="p-4">
        <h3>Your Info</h3>

        <Formik
          initialValues={{ first: '', last: '', email: '', phone:'', validateTableNo: validateTableNo, tableNumber: '', validateTerms: validateTerms, terms: false }}
          validationSchema={GuestInfoSchema}
          onSubmit={onConfirm}
          updateTableNo ={updateTableNo}
        >
          {({ dirty, errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>

            <FormikForm>

              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <ThemedFormControl
                  name="first"
                  ref={firstRef}
                  isInvalid={!!errors.first && !!touched.first}
                  value={values.first}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.first}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <ThemedFormControl
                  name="last"
                  isInvalid={!!errors.last && !!touched.last}
                  value={values.last}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.last}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Mobile Phone</Form.Label>
                <ThemedFormControl
                  name="phone"
                  type="tel"
                  maxLength="10"
                  isInvalid={!!errors.phone && !!touched.phone}
                  value={values.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </Form.Group>

              {updateTableNo && (<Form.Group>
                <Form.Label>Enter Table Number</Form.Label>
                <ThemedFormControl
                  name="tableNumber"
                  type="text"                  
                  maxLength="100"  
                  isInvalid={!!errors.tableNumber && !!touched.tableNumber}
                  value={values.tableNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.tableNumber}</Form.Control.Feedback>
              </Form.Group>)}

              {!hideTermsAndConditions && (<Form.Group>
                <Form.Check
                  required
                  name="terms"
                  label={
                    <span>
                      I agree to the&nbsp;
                      <ThemedAnchor href="https://www.boydgaming.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</ThemedAnchor> and&nbsp;
                      <ThemedAnchor href="https://www.boydgaming.com/disclaimer-and-terms" target="_blank" rel="noopener noreferrer">terms of use</ThemedAnchor>.
                    </span>
                  }
                  isInvalid={!!errors.terms && !!touched.terms}
                  feedback={errors.terms}
                  checked={values.terms}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>)}

              <div>
                <ThemedButton 
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Submit
                </ThemedButton>
                <ThemedLinkButton
                  variant="link"
                  onClick={() => onCancel()}
                >
                  Cancel
                </ThemedLinkButton>
              </div>

            </FormikForm>

          }
        </Formik>

      </Modal.Body>
    </Modal>
  );
};

GuestInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default GuestInfo;