import React from 'react';
import {Modal} from 'react-bootstrap';
import LoadingWithResultIndicator, {ResultStates} from '../components/LoadingWithResultIndicator';
import './LoadingWithResultModal.scss';
import classNames from 'classnames';
import TapAnywhereOverlay from '../components/TapAnywhereOverlay';

const LoadingWithResultModal = ({ show, isComplete, resultState = ResultStates.success, resultText, title, onTapToContinue }) => {
  const resultTextClassNames = classNames({
    'loading-with-result-modal__result-text': true,
    'loading-with-result-modal__result-text--success': resultState === ResultStates.success,
    'loading-with-result-modal__result-text--failure': resultState === ResultStates.failure,
    'loading-with-result-modal__result-text--animate': isComplete,
  });
  
  return (
    <>
      <Modal className="loading-with-result-modal" show={show} size="lg" centered>
        <Modal.Body className="p-0 d-flex flex-column align-items-center">
          {title &&
            <h3 className="p-3">{title}</h3>
          }
          <div className="w-50">
            <LoadingWithResultIndicator isComplete={isComplete} resultState={resultState}/>
          </div>
          <div className={`loading-with-result-modal__footer ${isComplete ? 'loading-with-result-modal__footer--visible' : ''}`}>
            {resultText && isComplete &&
              <h5 className={resultTextClassNames}>
                {resultText}
              </h5>
            }
          </div>
        </Modal.Body>
      </Modal>
      <TapAnywhereOverlay show={show && isComplete && resultState === ResultStates.failure} onTap={onTapToContinue} />
    </>
  );
};

export default LoadingWithResultModal;