import React from 'react';
import Numeral from 'numeral';
import { compose } from 'recompose';
import { Button, Card } from 'react-bootstrap';

import QuantityButton from './QuantityButton';
import { ORDER_STATUS_CANCELLED, ORDER_STATUS_ERROR, TEXT_CANCEL } from '../common/Constants';
import { PosApi } from '../common';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';
import { BottomButtonGroup, ConfirmCancelModal, Container, Loading, TopNavBar} from '../common/components';
import { ModifierLineItem} from '../common/components/ModifierLineItem';

import QuantityBadge from '../ordering/QuantityBadge';
import './Checkout.scss';
import InfoBubble from '../common/components/InfoBubble';

const Checkout = ({
  check,
  history,
  products,
  removeItem,
  updateCheck,
  updateQuantity,
  settings,
  toast,
}) => {	
  const [showWorking, setShowWorking] = React.useState(false);
  const [showCancel, setShowCancel] = React.useState(false);
  const [removeHashId, setRemoveHashId] = React.useState();
  const title = `Order Summary (${Numeral(check.subtotal).format('$0,0.00')})`;

  const handleCancel = () => {
    gotoCheckout(`?status=${ORDER_STATUS_CANCELLED}`);
  }

  const handlePay = async () => {
    setShowWorking(true);

    try {
      // TODO: Do not send to kitchen, just post
      const order = await PosApi.postCheck(check, settings);

      updateCheck({
        ...order,
        sent: true,
      });

      history.push('/playerswipe');
    }
    catch (err) {
      console.error(err);
      gotoCheckout(`?status=${ORDER_STATUS_ERROR}`);
    }
  };
  
  const gotoCheckout = (query) => history.push(`/thankyou${query}`);

  const handleQuantityChanged = (hashId, item, quantity) => {
    if (quantity === 0) {
      return setRemoveHashId(hashId);
    }

    let product = products[item.id];

    updateQuantity(hashId, quantity, product.price);
  };

  const handleRemove = () => {
    removeItem(removeHashId);
    setRemoveHashId();
  };

  const bottomButtons = [
    {
      name: 'Cancel Order',
      onClick: () => setShowCancel(true),
    },
    {
      onClick: handlePay,
      primary: true,
      content: (
        <>
          Continue <QuantityBadge quantity={check.totalQuantity} />
        </>
      ),
    },
  ];

  return (
    <Container className="checkout" style={{ paddingBottom: 100 }}>

      <div className="checkout__nav ">
        <Button
          variant="link"
          className="checkout__back"
          onClick={() => history.goBack()}
        >
          <i className="fa fa-chevron-left 2x" />
        </Button>
        <TopNavBar title={title} buttons={[]} />
      </div>

      <Loading icon="list" text="Updating order" show={showWorking} />

      {Object.keys(check.items).map((hashId) => {
        const item = check.items[hashId];

        return (
          <Card
            key={hashId}
            className="checkout__item"
          >
            <Card.Body className="checkout__iteminner" style={{ fontSize: '1rem' }}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="checkout__name">{item.name}</p>

                <div className="d-flex align-items-center">
                  <div className="checkout__price">{Numeral(item.price).format('$0,0.00')}</div>
                  <div className="checkout__quantity">

                    <QuantityButton onClick={() => handleQuantityChanged(hashId, item, item.quantity - 1)}>
                      <i className="fa fa-minus fa-fw" />
                    </QuantityButton>

                    <div className="mx-1" style={{ width: 25, textAlign: 'center' }}>{item.quantity}</div>

                    <QuantityButton onClick={() => handleQuantityChanged(hashId, item, item.quantity + 1)}>
                      <i className="fa fa-plus fa-fw" />
                    </QuantityButton>
                  </div>

                </div>
              </div>
              <div>
                {Object.keys(item.forcedMods).map(groupId =>
                  Object.keys(item.forcedMods[groupId]).map(modId =>
                    <ModifierLineItem key={modId} styleName={"checkout__mod"} name={item.forcedMods[groupId][modId].name} price={item.forcedMods[groupId][modId].price}/>
                  )
                )}
              </div>
              <div>
                {Object.keys(item.exceptionMods).map(groupId =>
                  Object.keys(item.exceptionMods[groupId]).map(modId =>
                    <ModifierLineItem key={modId} styleName={"checkout__mod"} name={item.exceptionMods[groupId][modId].name} price={item.exceptionMods[groupId][modId].price} />
                  )
                )}
              </div>

              {item.notes &&
                <p className="checkout__mod">
                  Notes 
                  <InfoBubble styleName={"checkout__mod"} infoText={item.notes}/>
                </p>
              }
            </Card.Body>
          </Card>

        );
      })}

      <ConfirmCancelModal
        message="Are you sure you want to remove this item?"
        show={!!removeHashId}
        onConfirm={handleRemove}
        onCancel={() => setRemoveHashId()}
      />

      <ConfirmCancelModal
        message={TEXT_CANCEL}
        show={showCancel}
        onConfirm={handleCancel}
        onCancel={() => setShowCancel(false)}
      />

      <BottomButtonGroup buttons={bottomButtons} />

    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast,
)(Checkout);