import React, {createContext, useCallback, useContext, useEffect} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {useHistory, useLocation} from 'react-router-dom';
import {MetadataContext} from './MetadataProvider';
import {getTerminalSetting} from '../TerminalHelpers';
import * as TerminalSettings from '../TerminalSettings';

const DEFAULT_TIMEOUT = 120000;

const TimeoutContext = createContext({});

const TimeoutProvider = ({ children }) => {
  
  const [isTimeoutReached, setIsTimeoutReached] = React.useState(false);
  const [timeoutCleanup, setTimeoutCleanup] = React.useState(null);
  
  const { terminal } = useContext(MetadataContext);
  
  const defaultTimeoutMilliseconds = terminal
    ? (getTerminalSetting(terminal, TerminalSettings.TIMEOUT) * 1000) ?? DEFAULT_TIMEOUT
    : DEFAULT_TIMEOUT;
  
  const [timeoutDurationMilliseconds, setTimeoutDurationMilliseconds] = React.useState(defaultTimeoutMilliseconds);
  
  const handleOnIdle = async () => {
    idleTimer.pause();

    if (timeoutCleanup) {
      await timeoutCleanup();
    }

    setIsTimeoutReached(true);
  };
  
  const idleTimer = useIdleTimer({  
    timeout: timeoutDurationMilliseconds,
    onIdle: handleOnIdle,
  });
  
  const resetTimeoutTimer = () => {
    setIsTimeoutReached(false);
    idleTimer.reset();
  };
  
  const resetTimeoutDurationToDefault = () => {
    setTimeoutDurationMilliseconds(defaultTimeoutMilliseconds);
  };
  
  const setTimeoutMilliseconds = (milliseconds) => {
    setTimeoutDurationMilliseconds(milliseconds);
  };

  // If the timeout duration changes then reset the idle timer
  useEffect(() => {
    resetTimeoutTimer();
  }, [timeoutDurationMilliseconds]);

  useEffect(() => {
    resetTimeoutDurationToDefault();
  }, [defaultTimeoutMilliseconds]);
  
  const value = {
    isTimeoutReached,
    resetTimeoutTimer,
    setTimeoutMilliseconds,
    resetTimeoutDurationToDefault,
    setTimeoutCleanup,
    pauseTimeoutTimer: idleTimer.pause,
    resumeTimeoutTimer: idleTimer.resume,
  };
  
  return (
    <TimeoutContext.Provider value={value}>
      {children}
    </TimeoutContext.Provider>
  );
};

export {
  TimeoutProvider,
  TimeoutContext,
}