import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';

const ThemedToastContainer = styled.div`
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
`;

const ToastContext = React.createContext();

const ToastProvider = ({
  children,
}) => {
  const showToast = React.useCallback(({ success, error }) => {
    const icon = success ? 'fa-check-circle' : 'fa-times-circle';
    const iconClassName = `fa ${icon} mr-2`;

    toast(
      <ThemedToastContainer className="p-3">
        <i className={iconClassName} />
        {success || error}
      </ThemedToastContainer>
    );
  }, []);

  return (
    <ToastContext.Provider value={{ toast: showToast }}>
      <>
        <ToastContainer
          autoClose={2500}
          closeButton={false}
          hideProgressBar={true}
          position="bottom-left"
          className="pt-toast-container"
          toastClassName='pt-toast'
          bodyClassName='pt-toast-body'
        />
        {children}
      </>
    </ToastContext.Provider>
  );
};

const withToast = Component => props => (
  <ToastContext.Consumer>
    {value => <Component {...props} {...value} />}
  </ToastContext.Consumer>
)

export default ToastProvider;
export {
  ToastContext,
  withToast,
};