import React from 'react';
import Lottie from '../components/Lottie';
import {LoadingCircleLottieData} from '../../common/images';

// See lottie animation at: https://iconscout.com/lottie-animation/loading-circle-5461127
const LoadingCircleLottie = () => {
  return (
    <Lottie animationData={LoadingCircleLottieData} loop />
  );
};

export default LoadingCircleLottie;