import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReceipt } from '../common/ReceiptHelpers';
import { Container } from 'react-bootstrap';
import { Loading } from '../common/components';

const ReceiptViewer = () => {
  const { revenueCenterId, orderId, receiptId } = useParams();

  const [receiptHtml, setReceiptHtml] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchReceipt = async () => {
      try {
        const receipt = await getReceipt(revenueCenterId, orderId, receiptId);

        // Only html receipts are currently supported
        if (receipt.contentType !== 'text/html') {
          setIsLoading(false);
        }

        setReceiptHtml(receipt.content);
      } catch (error) {
        console.error("Error fetching the receipt:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReceipt();
  }, [revenueCenterId, orderId, receiptId]);

  if (isLoading)
    return <Loading show={true} text="Loading" />;

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', paddingBottom: '100px' }}>
      {receiptHtml ? (
        <div dangerouslySetInnerHTML={{ __html: receiptHtml }} />
      ) : (
        <div className="mt-5">
          <h4>Whoops! No receipt was found.</h4>
          Please see a server for assistance.
        </div>
      )}
    </Container>
  );
};

export default ReceiptViewer;