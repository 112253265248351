import React, {useEffect, useRef} from 'react';
import {BottomButtonGroup} from '../../common/components';
import {useHistory} from 'react-router-dom';
import {Card, Form} from 'react-bootstrap';
import {ThemedButton} from '../../common/Themed';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

const AccountNumberSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Must be a valid account number')
});

const PlayerManualAccountEntry = ({ onSubmit }) => {
  const history = useHistory();
  
  const handleSkipSwipeClicked = () => {
    history.push('/pay');
  };
  
  const handleSubmit = (formValues) => {
    onSubmit(formValues.accountNumber);
  };
  
  return (
    <div className="player-swipe m-0 h-100">
      <div>
        <h1 className="player-swipe__title p-0 text-center">
          Enter Your Rewards Account Number
        </h1>
      </div>
      <div className="p-0 w-75 my-4">
        <Formik initialValues={{accountNumber: ''}} validationSchema={AccountNumberSchema} onSubmit={handleSubmit}>
          {({ errors, handleBlur, handleChange, values, touched }) =>
            <Card>
              <Card.Body>
                <FormikForm>
                  <Form.Group>
                    <Form.Label>
                      Account Number
                    </Form.Label>
                    <Form.Control
                      name="accountNumber"
                      isInvalid={!!errors.accountNumber && !!touched.accountNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.accountNumber}</Form.Control.Feedback>
                  </Form.Group>

                  <div className="w-100">
                    <ThemedButton type="submit" className="w-100">Continue</ThemedButton>
                  </div>
                </FormikForm>
              </Card.Body>
            </Card>
          }
        </Formik>
      </div>
      <div className="mb-5">
        <BottomButtonGroup buttons={[{
          name: 'Skip to Payment',
          primary: false,
          onClick: handleSkipSwipeClicked,
        }]} />
      </div>
    </div>
  );
};

export default PlayerManualAccountEntry;