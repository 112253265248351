import React from 'react';
import './ProductSelectionGroup.scss';
import Numeral from 'numeral';
import {Accordion, useAccordionToggle} from 'react-bootstrap';

const ProductSelectionGroup = ({
    title,
    selectableProducts,
    isExpanded,
    onChange,
    onHeaderClick,
    onRequiredSelectionsReached,
    minimumSelections,
    maximumSelections
  }) => {
  const [selectedProducts, setSelectedProducts] = React.useState({});
  // For usability, the entire container is clickable to toggle the checkbox
  const handleCheckboxContainerClicked = (id) => {
    const isCurrentlyChecked = selectedProducts[id];

    // Prevent selecting the checkbox if the maximum selections have been reached
    if (maxSelectionsReached && !isCurrentlyChecked) {
      return;
    }

    const newSelectedProducts = {...selectedProducts, [id]: !isCurrentlyChecked};
    setSelectedProducts(newSelectedProducts);

    if (onChange) {
      onChange(id, isCurrentlyChecked);
    }
    
    const newSelectedCount = Object
      .keys(newSelectedProducts)
      .filter(id => newSelectedProducts[id])
      .length;
    
    const isMinSelectionsReachedExactly = newSelectedCount === minimumSelections;
    const isRequiredSelectionsReached = minimumSelections > 0 && isMinSelectionsReachedExactly;
    
    if (onRequiredSelectionsReached && isRequiredSelectionsReached && !isCurrentlyChecked) {
      onRequiredSelectionsReached();
    }
  };

  const isSelected = (id) => {
    return selectedProducts[id];
  }
  
  const getRequirementsText = () => {
    if (minimumSelections > 0 && maximumSelections <= minimumSelections)
      return `Required  •  Choose ${minimumSelections}`;
    else if (minimumSelections > 0 && maximumSelections > minimumSelections)
      return `Required  •  Choose between ${minimumSelections} and ${maximumSelections}`;
    else if (minimumSelections === 0 && maximumSelections > 0)
      return `Optional  •  Choose up to ${maximumSelections}`;
    else
      return `Optional`;
  };
  
  const getSelectedCount = () => {
    return Object
      .keys(selectedProducts)
      .filter(id => selectedProducts[id])
      .length;
  }
  
  const maxSelectionsReached = maximumSelections > 0 && getSelectedCount() >= maximumSelections;
  const minSelectionsReached = minimumSelections <= 0 || getSelectedCount() >= minimumSelections;
  
  const accordionIconClass = isExpanded 
    ? 'product-select-group__title-arrow fa fa-solid fa-chevron-up' 
    : 'product-select-group__title-arrow fa fa-solid fa-chevron-up expanded';
  
  return (
    <div className='product-selection-group'>
      {/* This is the header of the selection group which includes the title and expands when clicked. */}
      <div className="product-selection-group__header" onClick={onHeaderClick}>
        <div className="product-selection-group__header-title">
          <h5>{title}</h5>
          {minimumSelections > 0 &&
            <i className={`ml-2 fa fa-${minSelectionsReached ? 'check-circle' : 'exclamation-triangle'} text-${minSelectionsReached ? 'success' : 'warning'}`}/>
          }
          <i className={accordionIconClass}/>
        </div>
        <div className="product-selection-group__header-requirements text-muted">
          {getRequirementsText()}
        </div>
      </div>
      <Accordion activeKey={isExpanded ? '0' : ''} defaultActiveKey='0'>
        <Accordion.Collapse eventKey="0">
          <div>
            {/* Shows a checkbox for each product along with its name and the additional price if present. */}
            {Object.keys(selectableProducts).map(id => (
              <div
                className="product-selection-group__product"
                key={id}
                onClick={() => handleCheckboxContainerClicked(id)}>
                <div className="product-selection-group__product-name">
                  <label htmlFor={id}>
                    {selectableProducts[id].name}
                  </label>
                </div>
                <div className="product-selection-group__product-checkbox">
                  {selectableProducts[id].price > 0 &&
                    <div className="product-selection-group__product-price text-muted">
                      +{Numeral(selectableProducts[id].price).format('$0,0.00')}
                    </div>
                  }
                  <input
                    type="checkbox"
                    id={id}
                    checked={selectedProducts[id] ?? false}
                    disabled={!isSelected(id) && maxSelectionsReached}
                    onChange={() => {
                    }}/>
                </div>
              </div>
            ))}
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default ProductSelectionGroup;