export const HOUSE_ADVANTAGE_TERMINAL_SETTING_NAME = 'house-advantage-card-regex';
export const HIDE_NAVBAR_INFO_BUTTON = 'pt-hide-navbar-info-button';
export const IS_DEVELOPER_MODE = 'pt-dev-mode';
export const TIMEOUT = 'pt-timeout';
export const LANDING_PAGE_URL = 'pt-landing-page-url';
export const FINALIZE_ORDER_REDIRECT_URL = 'pt-finalize-order-redirect-url';
export const FINALIZE_ORDER_TIMEOUT = 'pt-finalize-order-timeout';
export const BRAND_HERO_IMAGE_URL = 'pt-brand-hero';
export const BACKGROUND_IMAGE = 'pt-background-image-url';
export const PLAYER_SWIPE_CARD_BACKGROUND_IMAGE = 'pt-player-swipe-card-background-image-url';

