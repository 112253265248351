import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import ClampLines from 'react-clamp-lines';
import './ImageItem.scss';

const MIN_WIDTH = 576;

const Name = styled.div`
  color: ${props => props.theme.primaryColor};
`;

const Price = styled.div`
  color: ${props => props.theme.primaryColor};
`;

const ImageItem = ({ item }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const updateWidth = () => setWidth(window.innerWidth);

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <div className="it">

      <div className="it__metadata">
        <Name className="it__name">{item.name}</Name>

        {item.description &&
          <ClampLines
            text={item.description}
            lines={width > MIN_WIDTH ? 3 : 2}
            id={item.id}
            buttons={false}
            className="it__descr"
          />
        }

        {item.price !== undefined && item.price !== null &&
          <Price className="it__price">{Numeral(item.price).format('$0,0.00')}</Price>
        }
      </div>

      {item.image &&
        <div className="it__imgcontainer">
          <img className="it__img" src={item.image} alt={item.name} />
        </div>
      }

    </div>
  );
};

ImageItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ImageItem;